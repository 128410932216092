import React from "react";
import "./Unlock.css";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Applestore from "./../../../assets/download-on-the-app-store.svg";
import PlayStoreSvg from "./Images/google-play-badge-logo-svgrepo-com.svg";
function Unlock() {
  return (
    <div className="Unlock-Container">
      <div className="Angel-text-container-unlock">
        <p className="Angel-Moving-unlock">ANGEL-EARTH</p>
      </div>
      <div className="Unlock-txt">
        <h1 className="unlock-head">
          Angel-App,
          <br />
          You're saving more than You think.
          {/* <br /> and Achieve, The Unachievable. */}
        </h1>

        <p className="unlock-para">
          Innovative And Sustainable Solutions For Digitizing Life and Business
        </p>
        <div style={styles.ShineArrowContainer}>
          <a href="#why-angel-app" className="shine-arrow-container">
            <ArrowDownwardIcon className="shine-arrow-icon" />
          </a>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "2rem",
        }}
        id="issuediv"
      >
        <h5
          style={{
            fontSize: "21px",
            zIndex: "10",
            marginBottom: "-36px",
            textAlign: "center",
          }}
          className="UnlockHeading"
        >
          Get the App
        </h5>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            marginBottom: "-36px",
          }}
          className="IMGUnlockdivcontainer"
        >
          <a
            href="https://apps.apple.com/us/app/angel-app/id6473820385"
            target="_blank"
            rel="noopener noreferrer"
            style={{ cursor: "pointer", zIndex: "20" }}
          >
            <div
              style={{ width: "150px", zIndex: "12" }}
              className="UnlockIMGContainer"
            >
              <img
                src={Applestore}
                alt=""
                srcset=""
                style={{ width: "100%" }}
              />
            </div>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.angel.earth.angel.app"
            target="_blank"
            style={{ cursor: "pointer", zIndex: "20" }}
            rel="noopener noreferrer"
          >
            <div
              style={{ width: "166px", zIndex: "13", cursor: "pointer" }}
              className="UnlockIMGContainertwo"
            >
              <img
                src={PlayStoreSvg}
                alt=""
                srcset=""
                style={{ width: "100%" }}
              />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Unlock;

const styles = {
  heading: {
    textAlign: "center",
  },
  ShineArrowContainer: {
    height: "40px",
    width: "40px",
    background: "#389E47",
    borderRadius: "50%",
    zIndex: "100",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10px",
  },
};
