import { React, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import AccordionFeatFlyer from "../../services/accordion/AccordionFeatFlyer";
import AccorGrowFlyer from "../../services/accordion/AccorGrowFlyer";
import Information from "../../services/information/Information";
import Servicelists from "../../services/servicelists/Servicelists";
import "./NavigateComp.css";
import Flyer from "../../services/servicerender/ServiceComp/Flyer";
import { useNavigate, useLocation } from "react-router-dom";
function NavigateFlyer() {
  const navigate = useNavigate();
  const location = useLocation();
  const flyerRef = useRef(null);
  const scrollToFlyer = () => {
    if (location.pathname === "/services/flyer" && flyerRef.current) {
      window.scrollTo({
        top: flyerRef.current.offsetTop,
        behavior: "smooth",
      });
    } else {
      navigate("/services/flyer");
    }
  };
  useEffect(() => {
    if (location.pathname === "/services/flyer" && flyerRef.current) {
      window.scrollTo({
        top: flyerRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, [location.pathname]);
  
  const pageTitle =
    "Flyer - Engaging visual content for effective marketing campaigns";
  const pageDescription =
    "Explore the features and growth opportunities with Flyers. Enhance your marketing campaigns with visually appealing content to attract and engage customers.";
  const brandLogoUrl = `${process.env.PUBLIC_URL}/A-ELogo.png`;
  const cdnImageUrl =
    "https://images.pexels.com/photos/7869111/pexels-photo-7869111.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={brandLogoUrl} />
        <meta
          property="og:url"
          content="https://angel-earth.com/services/flyer"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={cdnImageUrl} />
      </Helmet>
      <Servicelists scrollToFlyer={scrollToFlyer} />
      <div ref={flyerRef}>
        <Flyer />
      </div>
      <div className="Services-grid">
        <div>
          <p className="Accor-head">FEATURES</p>
        </div>
        <div>
          <AccordionFeatFlyer />
        </div>
      </div>

      <div className="Services-grid">
        <div>
          <p className="Accor-head">
            HERE’S HOW WE
            <br /> HELP YOU GROW
          </p>
        </div>
        <div>
          <AccorGrowFlyer />
        </div>
      </div>

      <Information />
    </>
  );
}

export default NavigateFlyer;
