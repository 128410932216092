import React from "react";
import "./LegalPages.css";
import { Helmet } from "react-helmet";
function TermAndConditions() {
  const brandLogoUrl = `${process.env.PUBLIC_URL}/A-ELogo.png`;
  const cdnImageUrl =
    "https://images.pexels.com/photos/5324947/pexels-photo-5324947.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
  return (
    <>
      <Helmet>
        <title>Terms and Conditions - Angel Earth</title>
        <meta
          name="description"
          content="Read the terms and conditions of use for Angel Earth, covering user obligations, account management, and more."
        />
        <meta
          property="og:title"
          content="Terms and Conditions - Angel Earth"
        />
        <meta
          property="og:description"
          content="Read the terms and conditions of use for Angel Earth, covering user obligations, account management, and more."
        />
        <meta property="og:image" content={brandLogoUrl} />
        <meta
          property="og:url"
          content="https://angel-earth.com/terms-and-conditions"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Terms and Conditions - Angel Earth"
        />
        <meta
          name="twitter:description"
          content="Read the terms and conditions of use for Angel Earth, covering user obligations, account management, and more."
        />
        <meta name="twitter:image" content={cdnImageUrl} />
      </Helmet>

      <div className="the-main-container">
        <h1
          style={{ textAlign: "center", marginBottom: "0.5rem" }}
          className="heading-contest"
        >
          Terms and Conditions
        </h1>
        <p>
          These terms and conditions (the "Terms and Conditions") govern the use
          of
          <a href="www.angel-earth.com">www.angel-earth.com</a> (the "Site").
          This Site is owned and operated by Angel-Earth Corporation. This Site
          is an ecommerce website
        </p>
        <p>
          By using this Site, you indicate that you have read and understand
          these Terms and Conditions and agree to abide by them at all times.
        </p>

        <h4>Intellectual Property</h4>
        <p>
          All content published and made available on our Site is the property
          of Angel-Earth Corporation and the Site's creators. This includes, but
          is not limited to images, text, logos, documents, downloadable files
          and anything that contributes to the composition of our Site.
        </p>
        <h4>Age Restrictions</h4>
        <p>
          The minimum age to use our Site is 13 years old. By using this Site,
          users agree that they are over 13 years old. We do not assume any
          legal responsibility for false statements about age
        </p>
        <h4>Acceptable Use</h4>
        <p>
          As a user of our Site, you agree to use our Site legally, not to use
          our Site for illegal purposes, and not to:
        </p>
        <ul>
          <li>
            <strong>Harass or mistreat other users of our Site:</strong>
          </li>

          <li>
            <strong>-Violate the rights of other users of our Site:</strong>
          </li>

          <li>
            <strong>
              -  Violate the intellectual property rights of the Site owners or
              any third party to the Site:
            </strong>
          </li>
          <li>
            <strong>
              -  Hack into the account of another user of the Site:
            </strong>
          </li>

          <li>
            <strong>
              -  Act in any way that could be considered fraudulent: or
            </strong>
          </li>
          <li>
            <strong>
              -  Post any material that may be deemed inappropriate or
              offensive. <br />
              If we believe you are using our Site illegally or in a manner that
              violates these Terms and Conditions, we reserve the right to
              limit, suspend or terminate your access to our Site. We also
              reserve the right to take any legal steps necessary to prevent you
              from accessing our Site.
            </strong>
          </li>
        </ul>
        <div>
          <h4>Accounts</h4>
          <strong>
            When you create an account on our Site, you agree to the following:
          </strong>

          <ol>
            <li>
              <strong>
                You are solely responsible for your account and the security and
                privacy of your account, including passwords or sensitive
                information attached to that account: and
              </strong>
            </li>
            <li>
              <strong>
                All personal information you provide to us through your account
                is up to date, accurate, and truthful and that you will update
                your personal information if it changes.
              </strong>
            </li>
          </ol>

          <p>
            We reserve the right to suspend or terminate your account if you are
            using our Site illegally or if you violate these Terms and
            Conditions.
          </p>

          <h4>Sale of Goods</h4>
          <p>
            These Terms and Conditions govern the sale of goods available on our
            Site.
            <br />
            <strong> The following goods are available on our Site : </strong>
            <strong>- Digital Subscriptions.</strong>
          </p>
          <div>
            <p>
              These Terms and Conditions apply to all the goods that are
              displayed on our Site at the time you access it. This includes all
              products listed as being out of stock. All information,
              descriptions, or images that we provide about our goods are as
              accurate as possible. However, we are not legally bound by such
              information, descriptions, or images as we cannot guarantee the
              accuracy of all goods we provide. You agree to purchase goods from
              our Site at your own risk.
            </p>
            <p>
              We reserve the right to modify, reject or cancel your order
              whenever it becomes necessary. If we cancel your order and have
              already processed your payment, we will give you a refund equal to
              the amount you paid. You agree that it is your responsibility to
              monitor your payment instrument to verify receipt of any refund.
            </p>
            <strong> Subscriptions </strong>
            <p>
              Your subscription automatically renews and you will be
              automatically billed until we receive notification that you want
              to cancel the subscription.
            </p>
            <p>
              <strong>
                To cancel your subscription, please follow these steps:
              </strong>
              All paid Angel-Earth plans are billed monthly, with no long-term
              contracts. Angel-Earth subscriptions are calculated on a
              per-account basis for Businesses and Personal plans. As per
              metered (Pay Per Click) billing, this product will also be billed
              respectively with the subscription type. Angel-Earth Corporation
              reserves the right to refuse to provide Angel-Earth Subscription
              to any customer that is using the Angel-Earth/Angel-App platforms
              inappropriately.
            </p>
            <h4>Free Trial</h4>
            <p>
              <strong>We offer the following free trial of our goods:</strong>{" "}
              All Angel-Earth Corporation free trials are respective to the
              subscription type that the user chooses.
            </p>
            <p>
              <strong>
                At the end of your free trial, the following will occur:
              </strong>
              The subscription type will continue after the free trial is
              finished, after a successful payment has been withdrawn
            </p>
            <p>
              <strong>
                To cancel your free trial, please follow these steps:
              </strong>
              Log into your account, select profile, and "Cancel Free Trial"
            </p>
            <strong>Payments </strong>
            <strong>
              We accept the following payment methods on our Site:
            </strong>
            <ul>
              <li>
                <strong>-  Credit Card:</strong>
              </li>
              <li>
                <strong>-  Major cards including:</strong>
              </li>
              <li>
                <strong>-  VISA:</strong>
              </li>
              <li>
                <strong>-  VISA Debit:</strong>
              </li>
              <li>
                <strong>-  MasterCard:</strong>
              </li>
              <li>
                <strong>-  Discover:</strong>
              </li>
              <li>
                <strong>-  JCB: and</strong>
              </li>

              <li>
                {" "}
                <strong>- American Express. </strong>
              </li>
            </ul>
            <p>
              When you provide us with your payment information, you authorize
              our use of and access to the payment instrument you have chosen to
              use. By providing us with your payment information, you authorize
              us to charge the amount due to this payment instrument.
            </p>
            <p>
              If we believe your payment has violated any law or these Terms and
              Conditions, we reserve the right to cancel or reverse your
              transaction.
            </p>
            <h4>Consumer Protection Law</h4>
            <p>
              Where the Consumer Protection Act, or any other consumer
              protection legislation in your jurisdiction applies and cannot be
              excluded, these Terms and Conditions will not limit your legal
              rights and remedies under that legislation. These Terms and
              Conditions will be read subject to the mandatory provisions of
              that legislation. If there is a conflict between these Terms and
              Conditions and that legislation, the mandatory provisions of the
              legislation will apply
            </p>
            <h4>Links to Other Websites</h4>
            <p>
              Our Site contains links to third party websites or services that
              we do not own or control. We are not responsible for the content,
              policies, or practices of any third party website or service
              linked to on our Site. It is your responsibility to read the terms
              and conditions and privacy policies of these third party websites
              before using these sites.
            </p>
            <h4>Limitation of Liability</h4>
            <p>
              Angel-Earth Corporation and our directors, officers, agents,
              employees, subsidiaries, and affiliates will not be liable for any
              actions, claims, losses, damages, liabilities and expenses
              including legal fees from your use of the Site.
            </p>
            <h4>Indemnity</h4>
            <p>
              Except where prohibited by law, by using this Site you indemnify
              and hold harmless Angel-Earth Corporation and our directors,
              officers, agents, employees, subsidiaries, and affiliates from any
              actions, claims, losses, damages, liabilities and expenses
              including legal fees arising out of your use of our Site or your
              violation of these Terms and Conditions.
            </p>
            <h4>Applicable Law</h4>
            <p>
              These Terms and Conditions are governed by the laws of the
              Province of Alberta.
            </p>
            <h4>Severability</h4>
            <p>
              If at any time any of the provisions set forth in these Terms and
              Conditions are found to be inconsistent or invalid under
              applicable laws, those provisions will be deemed void and will be
              removed from these Terms and Conditions. All other provisions will
              not be affected by the removal and the rest of these Terms and
              Conditions will still be considered valid.
            </p>
            <h4>Changes</h4>
            <p>
              These Terms and Conditions may be amended from time to time in
              order to maintain compliance with the law and to reflect any
              changes to the way we operate our Site and the way we expect users
              to behave on our Site. We will notify users by email of changes to
              these Terms and Conditions or post a notice on our Site.
            </p>
            <h4>Contact Details</h4>
            <p>
              Please contact us if you have any questions or concerns. Our
              contact details are as follows
            </p>
            <a href="mailto:welcome@angel-earth.com">welcome@angel-earth.com</a>{" "}
            <br />
            <strong>Effective Date:</strong>
            <span> 5th day of March, 2022 </span>
            <p>
              <strong>Last Updated:</strong> 1st day of January, 2024
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermAndConditions;
