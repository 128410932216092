import React from "react";
import { NavLink } from "react-router-dom";
import "./Explore.css";
function Explore() {
  return (
    <>
      <div className="Explore-div">
        <div className="heading" style={{ width: "60%" }}>
          <h1 className="Exp-head Exp-head-one ">Transform Your Business</h1>
          <h1 className="Exp-head  "> with Angel-App</h1>

          <p style={{}} className="exp-p">
            Empowering Businesses with Digital Receipts, Coupons and Flyers{" "}
          </p>
        </div>

        <div style={{ width: "40%" }} className="exp-btn-div">
          <button className="Explore-button">
            <NavLink
              to="/services/dtransfer"
              className="NavLinks-Text"
              style={{ color: "black", textAlign: "center" }}
              activeClassName="active"
            >
              Explore Products
            </NavLink>
          </button>
        </div>
      </div>
    </>
  );
}

export default Explore;
