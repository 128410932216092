import React, { useState } from "react";
import { Tooltip, Typography, Box } from "@mui/material";
import "./ToolTip.css";
const GlobalDiscountTooltip = () => {
  const handleDownload = () => {
    const userAgent = navigator.userAgent;
  
    if (/android/i.test(userAgent)) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.angel.earth.angel.app",
        "_blank"
      );
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
      window.open(
        "https://apps.apple.com/us/app/angel-app/id6473820385",
        "_blank"
      );
    } else {
      window.open(
        "https://dashboard.angel-earth.com/#/login",
        "_blank"
      );
    }
  };
  

  const [open, setOpen] = useState(true);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight={"35px"}
      bgcolor="#389E47"
    >
      {open && (
        <Tooltip>
          <Typography
            variant="body1"
            color="#ffffffde"
            fontWeight={450}
            className="Tooltip-TypoG"
          >
            For a Limited time. Get a 100% discount for the initial 2 months
            with promo code "Future100". Cancel anytime.
            <span
              onClick={handleDownload}
              style={{
                color: "#ffffffde",
                textDecoration: "none",
                borderBottom: "1px solid #ffffffde",
                cursor:"pointer"
              }}
            >
              Subscribe now!
            </span>
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};

export default GlobalDiscountTooltip;
