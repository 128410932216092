import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./src/NavBar/Navbar";
import Footer from "./src/Components/Dtransfer/DtransferFooter/Footer";
import HomePage from "./src/pages/HomePage/Homepage";
import AboutUsPage from "./src/pages/AboutUs/AboutUsPage";
import NavigatePushRadius from "./src/Components/NavigateComponents/NavigateServices/NavigatePushRadius";
import NavigateCoupon from "./src/Components/NavigateComponents/NavigateServices/NavigateCoupon";
import PricingPage from "./src/pages/Pricing/PricingPage";
import NavigateStorage from "./src/Components/NavigateComponents/NavigateServices/NavigateStorage";
import DTransfer from "./src/pages/D-Transfer/DTransfer";
import NavigateFlyer from "./src/Components/NavigateComponents/NavigateServices/NavigateFlyer";
import ContactUsPage from "./src/pages/ContactUs/ContactUsPage";
import NavigateOneDayCampaign from "./src/Components/NavigateComponents/NavigateServices/NavigateOneDayCamp.js";
import LoginPage from "./src/pages/LoginPage/LoginPage";
import NavigatePushNotification from "./src/Components/NavigateComponents/NavigateServices/NavigatePushNotification";
import SignUpPage from "./src/pages/SignUpPage/SignUpPage";
import NavigateDTrans from "./src/Components/NavigateComponents/NavigateServices/NavigateDTrans";
import DtransferReadmore from "./src/Components/Dtransfer/DtransferReadmore/DtransferReadmore.js";
import Culturenavigate from "./src/Components/NavigateAboutComponents/Culture/NavigateCulture.js";
import StoryNavigate from "./src/Components/NavigateAboutComponents/Story/NavigateStory.js";
import DtransDocuments from "./src/Components/DtransDocuments/DtransDocuments.js";
import PrivacyPolicy from "./src/pages/LegaLPages/PrivacyPolicy.js";
import TermAndConditions from "./src/pages/LegaLPages/TermAndConditions.js";
import POPUP from "./src/Components/POPUP.js";
import ScrollToTopButton from "./src/Components/JumptoTopBTN/JumptoTopBTN.js";
import WhatsAppWidget from "./src/Components/WhatsAppWidget/WhatsAppWidget.jsx";
import NotFoundPage from "./src/Components/NotFoundPage.jsx";
function App() {
  return (
    <div className="App" style={{ position: "relative" }}>
      <BrowserRouter>
        <Navbar />
        <ScrollToTopButton />
        <WhatsAppWidget/>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/products" element={<NavigateDTrans />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
          <Route path="/why-angel-app" element={<DTransfer />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/services/dtransfer" element={<NavigateDTrans />} />
          <Route path="/services" element={<NavigateDTrans />} />
          <Route path="/services/Pushradius" element={<NavigatePushRadius />} />
          <Route path="/services/coupon" element={<NavigateCoupon />} />
          <Route
            path="/services/pushnotifications"
            element={<NavigatePushNotification />}
          />
          <Route path="/services/storage" element={<NavigateStorage />} />
          <Route
            path="/serviceAchievements/storage"
            element={<NavigateStorage />}
          />
          <Route path="/services/flyer" element={<NavigateFlyer />} />
          <Route
            path="/services/onedaycampaign"
            element={<NavigateOneDayCampaign />}
          />
          <Route path="/about/story" element={<StoryNavigate />} />
          <Route path="/about/culture" element={<Culturenavigate />} />
          <Route path="/documents" element={<DtransDocuments />} />
          <Route path="/dtransfer/redmore" element={<DtransferReadmore />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermAndConditions />} />
          <Route path="/pop-up" element={<POPUP />} />
          <Route path="*" element={<NotFoundPage />}/>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}
export default App;
