import React, { useState } from "react";
import "./Accordion.css";
const AccorGrowStorage = () => {
  const items = [
    {
      title: "Collaborative Workflows:",
      Number: "01",
      content:
        "Foster teamwork with shared storage spaces, allowing real-time access and collaboration on documents. With Angel-App's collaborative workflows, teams can work together seamlessly, sharing and collaborating on documents in real-time, regardless of location.",
    },
    {
      title: "Streamlined Document Management:",
      Number: "02",

      content:
        "Simplify business document processes by securely storing contracts, invoices, and essential documents, reducing reliance on physical paperwork. Angel-App's streamlined document management features help businesses digitize their document workflows, reducing paperwork, minimizing errors, and improving overall efficiency.",
    },
    {
      title: "Effortless Organization:",
      Number: "03",

      content:
        "Organize digital documents effortlessly with easy categorization, tagging, and searching features, streamlining personal or professional document management. With Angel-App's intuitive organization tools, users can quickly categorize, tag, and search for documents, making it easy to find what they need when they need it.",
    },
    {
      title: "Compliance and Security:",
      Number: "04",

      content:
        "Meet compliance requirements confidently with Angel-App's Storage, adhering to stringent security standards for storing sensitive documents. Angel-App's compliance and security features ensure that stored documents meet regulatory requirements and industry standards, reducing the risk of non-compliance and potential penalties.",
    },
    {
      title: "Collaborate for Targeted Success:",
      Number: "05",

      content:
        "Engage with Angel-App to harness the full potential of Storage with Push Radius, Push notifications, Coupons, Flyers, and 1 Day Campaign. Implement precise and effective promotional strategies, enhancing brand visibility, and driving customer engagement. Partner with Angel-App for a strategic approach to your promotional endeavors, leveraging advanced features and insights to achieve targeted success.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderItems = items.map((item, index) => {
    const isActive = index === activeIndex;
    const icon = isActive ? "-" : "+";

    return (
      <div key={index} className={`accordion-item ${isActive ? "active" : ""}`}>
        <div className="accordion-title" onClick={() => onTitleClick(index)}>
          <span>
            {" "}
            <span className="acco-num">{item.Number}</span> {item.title}
          </span>

          <span className="accordion-icon">{icon}</span>
        </div>
        {isActive && <div className="accordion-content">{item.content}</div>}
      </div>
    );
  });

  return <div className="accordion">{renderItems}</div>;
};

export default AccorGrowStorage;
