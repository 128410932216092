import React, { useState } from "react";
import "./Dtrans.css";
const AccordionWhyAngel = () => {
  const items = [
    {
      title: "What is Angel-Earth's mission?",
      Number: "01",
      content:
        "Angel-Earth is dedicated to catalyzing a paradigm shift in the landscape of digital document management for businesses. By leveraging cutting-edge technologies, the mission is to revolutionize traditional document-handling practices, fostering heightened efficiency, and championing sustainable approaches in the digital era. The overarching goal is to reshape how enterprises engage with and process documents, transcending conventional limitations to create a more streamlined and eco-conscious future..",
    },
    {
      title: "Who supports Angel-Earth?",
      Number: "02",

      content:
        "Angel-Earth proudly boasts the backing of esteemed entities such as Technology Alberta and Alberta Innovates, both integral subsidiaries of the Government of Alberta. The support from these institutions underscores the strategic alignment of Angel-Earth's mission with broader regional initiatives, highlighting its significance in advancing technological innovation within the province",
    },
    {
      title: "What breakthrough technology does Angel-Earth offer?",
      Number: "03",

      content:
        "Angel-Earth stands at the forefront of innovation with groundbreaking technologies like dTransfer and Angel-AI. These transformative elements form a cohesive framework aimed at digitizing and optimizing document management processes. dTransfer, a pivotal feature, acts as a secure conduit for the seamless transition of physical documents into a digital format, while Angel-AI introduces advanced artificial intelligence to enhance overall operational efficiency",
    },
    {
      title: "What sectors does Angel-Earth serve?",
      Number: "04",

      content:
        "Angel-Earth strategically caters to diverse sectors, encompassing a wide spectrum of industries. The primary focus lies in elevating document management practices across businesses, irrespective of their field, thereby contributing to a more interconnected and technologically empowered global business environment.",
    },

    {
      title: "How does Angel-Earth contribute to sustainability?",
      Number: "05",

      content:
        "Angel-Earth actively champions sustainability by mitigating the environmental impact traditionally associated with paperwork. Through the widespread adoption of digital solutions, the organization significantly reduces paper consumption, contributing to a greener and more eco-friendly business ecosystem. This commitment aligns with global efforts to foster sustainable practices and reduce the ecological footprint associated with conventional document management",
    },
    {
      title: "What is Angel-App?",
      Number: "06",

      content:
        "Angel-App represents a pioneering digital tool meticulously crafted to optimize receipt and data management for businesses. Leveraging innovative technologies such as dTransfer and Angel-AI, this application redefines conventional approaches, offering a comprehensive solution for streamlined and digitized document handling.",
    },
    {
      title: "What is dTransfer, and how does it work?",
      Number: "07",

      content:
        "At the core of Angel-App, dTransfer emerges as a revolutionary feature designed to digitally transform receipts, invoices, and crucial documents. This sophisticated mechanism securely transports physical documents into a private cloud space, ensuring accessibility and data integrity. The intricate workings of dTransfer seamlessly integrate into the broader framework of Angel-App, providing users with a sophisticated and user-friendly document management experience.",
    },
    {
      title: "How does Angel-App handle promotions?",
      Number: "08",

      content:
        "Angel-App introduces a multifaceted approach to promotions, featuring Coupons, Flyers, and a distinctive 1 Day Campaign feature. This digital platform empowers businesses to engage in effective advertising and promotional activities, providing a dynamic and interactive space for consumers to explore exclusive offers and discounts.",
    },
    {
      title: "What is the purpose of the 1 Day Campaign?",
      Number: "09",

      content:
        "The 1 Day Campaign within Angel-App serves a pivotal role in orchestrating short-term promotional initiatives. This feature introduces exclusive offers and discounts, fostering a dynamic and engaging shopping experience for users. By creating a sense of urgency and exclusivity, the 1 Day Campaign augments the effectiveness of promotional strategies, contributing to a heightened level of consumer engagement.",
    },
    {
      title: "How does Angel-App support businesses and individuals?",
      Number: "10",

      content:
        "Angel-App extends its support across multiple dimensions, assisting businesses in achieving sustainability objectives, empowering individuals to digitize their personal and professional lives, and facilitating families in saving financial resources. The application achieves this by offering a diverse array of products, including coupons and flyers, tailored to meet the unique needs of businesses and end-users alike.",
    },
    {
      title: "What role does Predictive AI play in Angel-App?",
      Number: "11",

      content:
        "At the technological core of Angel-App lies the predictive power of Angel-AI, a sophisticated artificial intelligence system that permeates various facets of the application. By harnessing Predictive AI, Angel-App delivers personalized user experiences, targeted promotions, and highly efficient document management. The optimal functioning of Angel-AI is most pronounced when businesses leverage the entire suite of products collectively, unlocking the full potential of predictive analytics.",
    },
    {
      title: "Is there a limit to document storage in Angel-App?",
      Number: "12",

      content:
        "Angel-App provides users with comprehensive document storage solutions, with the option to acquire additional cloud space if required. It operates on a retention model, preserving data for a period of 90 days following any downgrade in subscription plans. This nuanced approach to storage management ensures flexibility and adaptability to users' evolving document management needs.",
    },
    {
      title: "How can users earn additional cloud space on Angel-App?",
      Number: "13",

      content:
        "In a bid to incentivize user engagement and expansion, Angel-App offers a mechanism for users to accrue additional cloud space. By simply sharing the application with 10 mobile contacts, users can augment their storage capacity by an extra 500MB. This strategic approach not only fosters user growth but also establishes a symbiotic relationship where the features of expanded storage are reciprocally linked to the app's user base expansion. Additionally, users are granted an initial 500MB of complimentary data upon onboarding, further enhancing the overall value proposition",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderItems = items.map((item, index) => {
    const isActive = index === activeIndex;
    const icon = isActive ? "-" : "+";

    return (
      <div key={index} className={`accordion-item ${isActive ? "active" : ""}`}>
        <div className="accordion-title" onClick={() => onTitleClick(index)}>
          <span>
            <span className="acco-num">{item.Number}</span> {item.title}
          </span>

          <span className="accordion-icon">{icon}</span>
        </div>
        {isActive && <div className="accordion-content">{item.content}</div>}
      </div>
    );
  });

  return <div className="dtrans-why-accorion">{renderItems}</div>;
};

export default AccordionWhyAngel;
