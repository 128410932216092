import React, { useState } from "react";

function CodesDocuments() {
  const [selectedLanguage, setSelectedLanguage] = useState("Ruby");

  const codeSnippets = {
    Ruby: `
    require 'sinatra'
    require 'net/http'
    
    post '/ae' do
      uri = URI(' https://r1mtp5lnnf.execute-api.us-west-2.amazonaws.com/Prod/ae-connect')
      Net::HTTP.post_form(uri, {})
      "Endpoint activated"
    end
    `,
    Python: `
    from flask import Flask
    import requests
    
    app = Flask(__name__)
    
    @app.route('/ae', methods=['POST'])
    def ae():
        requests.post(' https://r1mtp5lnnf.execute-api.us-west-2.amazonaws.com/Prod/ae-connect)
        return "Endpoint activated", 200
    `,
    Java: `
      

      public class Main {
        public static void main(String[] args) {
          try {
            URL url = new URL("https://example.com/endpoint");
            HttpURLConnection con = (HttpURLConnection) url.openConnection();
            con.setRequestMethod("POST");
            con.setDoOutput(true);

            System.out.println(response.toString());
          } catch (Exception e) {
            e.printStackTrace();
          }
        }
      }
    `,

    Go: `
    package main

    import (
        "fmt"
        "net/http"
    )
    
    func aeHandler(w http.ResponseWriter, r *http.Request) {
        http.Post("https://r1mtp5lnnf.execute-api.us-west-2.amazonaws.com/Prod/ae-connect", "application/json", nil)
        fmt.Fprint(w, "Endpoint activated")
    }
    
    func main() {
        http.HandleFunc("/ae", aeHandler)
        http.ListenAndServe(":8080", nil)
    }
    `,
    PHP: `
      <?php
      if ($_SERVER['REQUEST_METHOD'] === 'POST') {
          $ch = curl_init(' https://r1mtp5lnnf.execute-api.us-west-2.amazonaws.com/Prod/ae-connect');
          curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
          curl_setopt($ch, CURLOPT_POST, 1);
          $response = curl_exec($ch);
          curl_close($ch);
          echo "Endpoint activated";
      }
      ?>
    `,
    Node: `
      const express = require('express');
      const axios = require('axios');
      const app = express();

      app.post('/ae', async (req, res) => {
          await axios.post(' https://r1mtp5lnnf.execute-api.us-west-2.amazonaws.com/Prod/ae-connect');
          res.send('Endpoint activated');
      });

      app.listen(3000);
    `,
    DotNet: `
      using System.Net.Http;
      using Microsoft.AspNetCore.Mvc;

      [Route("api")]
      public class AEController : Controller
      {
          [HttpPost("ae")]
          public IActionResult ActivateEndpoint()
          {
              using (var client = new HttpClient())
              {
                  client.PostAsync(" https://r1mtp5lnnf.execute-api.us-west-2.amazonaws.com/Prod/ae-connect", null).Wait();
              }
              return Ok("Endpoint activated");
          }
      }
    `,
  };

  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
  };

  return (
    <>
      <h2 style={{color:"#d9d5d5"}}>Select a Programming Language</h2>
      <div style={{ display: "flex", justifyContent: "space-around",flexWrap:"wrap" }}>
        {Object.keys(codeSnippets).map((language) => (
          <button
            key={language}
            onClick={() => handleLanguageSelect(language)}
            style={{ cursor: "pointer",background:"#909090" }}
          >
            {language}
          </button>
        ))}
      </div>
      <div>
        <h3 style={{color:"#d9d5d5"}}>Code Snippet for POST Request</h3>

        <div style={{ border: "2px solid #909090",height:"45vh",overflow:"hidden" }}>
          <pre style={{ fontSize: "9px" }}>
            {codeSnippets[selectedLanguage]}
          </pre>
         
        </div>
        <button
            onClick={() => {
              navigator.clipboard.writeText(codeSnippets[selectedLanguage]);
              alert("Code copied to clipboard!");
            }}
            style={{ cursor: "pointer",background:"#909090",marginTop:"5px" }}

          >
            Copy Code
          </button>
      </div>
    </>
  );
}

export default CodesDocuments;
