import React, { useState } from "react";
import "./Accordion.css";
const AccorFeatCoupon = () => {
  const items = [
    {
      title: "Targeted Promotions:",
      Number: "01",
      content:
        "Craft promotions tailored to specific customer segments for effective marketing. By understanding the unique preferences and behaviors of different customer groups, businesses can create offers that resonate deeply and drive higher engagement and conversion rates.",
    },
    {
      title: "Predictive AI Analytics:",
      Number: "02",

      content:
        "Gain insights into customer behavior for strategic decision-making. With Predictive AI Analytics, businesses can analyze past purchasing patterns, identify trends, and predict future consumer behavior, allowing for more informed decisions and refined marketing strategies.",
    },
    {
      title: "Eco-Friendly Initiatives:",
      Number: "03",

      content:
        "Support sustainability for positive social and environmental impacts. Angel-App's coupon platform promotes eco-friendly initiatives by reducing the need for paper-based promotions, contributing to a greener and more sustainable business environment.",
    },
    {
        title: "dTransfer Integration:",
        Number: "04",
  
        content:
          "Connect coupon data with dTransfer for a comprehensive digital experience. By integrating with dTransfer, businesses can seamlessly manage and distribute coupons digitally, ensuring a streamlined and eco-friendly approach to promotional activities.",
      },
   
     
     
   
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderItems = items.map((item, index) => {
    const isActive = index === activeIndex;
    const icon = isActive ? "-" : "+";

    return (
      <div key={index} className={`accordion-item ${isActive ? "active" : ""}`}>
        <div className="accordion-title" onClick={() => onTitleClick(index)}>
          <span>
            {" "}
            <span className="acco-num">{item.Number}</span> {item.title}
          </span>

          <span className="accordion-icon">{icon}</span>
        </div>
        {isActive && <div className="accordion-content">{item.content}</div>}
      </div>
    );
  });

  return <div className="accordion">{renderItems}</div>;
};

export default AccorFeatCoupon;
