import React, { useState } from "react";
import "./Accordion.css";
const AccorGrowOneDay = () => {
  const items = [
    {
      title: " Real-Time Engagement:",
      Number: "01",
      content:
        "Embrace real-time engagement with customers through the dynamic nature of the 1 Day Campaign. Stay connected, build excitement, and create lasting impressions with short-term, impactful promotions. By maintaining a constant presence and offering daily deals, businesses can keep customers engaged and eager to participate in future campaigns.",
    },
    {
      title: "Customer Retention and Acquisition:",
      Number: "02",

      content:
        "Use the 1 Day Campaign to retain existing customers and attract new ones. The allure of daily promotions enhances customer loyalty, while the exclusivity of limited-time deals attracts a fresh audience eager to explore your offerings. By consistently offering valuable deals and experiences, businesses can build strong relationships with customers and drive long-term growth.",
    },
    {
      title: "Data-Driven Decision-Making:",
      Number: "03",

      content:
        "Leverage data-driven insights from each campaign to refine your promotional strategy. Understand customer responses, optimize offerings, and make informed decisions that elevate your business's marketing approach. By analyzing campaign performance metrics, businesses can identify trends, preferences, and opportunities for improvement, ensuring that each promotion is more effective than the last.",
    },
    {
      title: "Enhanced Visibility:",
      Number: "04",
      content:
        "Enhance your business's visibility by participating in the 1 Day Campaign. Joining this innovative promotional platform positions your brand in front of a diverse audience, driving traffic and increasing brand awareness. By leveraging Angel-App's extensive reach and marketing resources, businesses can expand their presence in the market and attract new customers with compelling offers and promotions.",
    },
    {
      title: "Collaborate for Targeted Success:",
      Number: "05",

      content:
        "Engage with Angel-App to harness the full potential of 1 Day Campaigns with Push Radius, Push notifications, Coupons, Flyers, and Storage. Implement precise and effective promotional strategies, enhancing brand visibility, and driving customer engagement. Partner with Angel-App for a strategic approach to your promotional endeavors, leveraging advanced features and insights to achieve targeted success.",
    },
    
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderItems = items.map((item, index) => {
    const isActive = index === activeIndex;
    const icon = isActive ? "-" : "+";

    return (
      <div key={index} className={`accordion-item ${isActive ? "active" : ""}`}>
        <div className="accordion-title" onClick={() => onTitleClick(index)}>
          <span>
            {" "}
            <span className="acco-num">{item.Number}</span> {item.title}
          </span>

          <span className="accordion-icon">{icon}</span>
        </div>
        {isActive && <div className="accordion-content">{item.content}</div>}
      </div>
    );
  });

  return <div className="accordion">{renderItems}</div>;
};

export default AccorGrowOneDay;
