import React from "react";
import "./ServiceComp.css";
import { useNavigate } from "react-router-dom";
function DTransfer() {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      navigate("/why-angel-app");
    }, 100);
  };
  return (
    <>
      <div className="Dtransfer-body">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-headdiv">
            <p className="headData">
              <span className="DtransStrk">d</span>TRANSFER
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="Dtransfer">
              {/* <div>
                <span className="verticle-line"></span>
              </div> */}
              <div className="DtransTxt">
                <p>
                  dTransfer is revolutionizing the business landscape by
                  fundamentally reshaping how organizations manage documents,
                  optimizing internal processes, and ensuring robust data
                  security measures. Through innovative solutions, we empower
                  businesses to streamline their operations, increase
                  efficiency, and adapt to the evolving demands of the digital
                  age. Our comprehensive suite of services encompasses document
                  digitization, workflow automation, and encrypted storage
                  solutions, enabling seamless collaboration and access to
                  critical information from anywhere, at any time. By leveraging
                  cutting-edge technologies and industry best practices, we
                  enable businesses to transcend traditional barriers, unlock
                  new opportunities, and achieve sustainable growth. With
                  dTransfer as their trusted partner, businesses can confidently
                  navigate the complexities of document management, driving
                  productivity and success in an increasingly competitive market
                  landscape.
                </p>
              </div>
            </div>

            <div className="Dtransfer-btn-container">
              <button
                className="D-transform-btn"
                onClick={handleButtonClick}
                style={{ cursor: "pointer" }}
              >
                Why Angel-App
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DTransfer;
