import React, { useState } from "react";
import "./Accordion.css";
const AccorFeatDtrans = () => {
  const items = [
    {
      title: "Digital Receipts:",
      Number: "01",
      content:
        "Digitizing receipts through Angel-App's QR code scan simplifies the cumbersome process of managing paper receipts. With dTransfer's secure digital storage, users can effortlessly organize and access their receipts from any device, ensuring they never lose track of important documents again. This seamless integration of technologies not only enhances user convenience but also reduces the environmental impact of paper waste ",
    },
    {
      title: "Effortless Organization:",
      Number: "02",

      content:
        "With dTransfer's intuitive tools, organizing documents becomes a breeze. Users can categorize and label receipts with ease, enabling swift retrieval when needed. By streamlining the organization process, dTransfer empowers users to efficiently manage their documents, saving valuable time and resources in the process",
    },
    {
      title: "Enhanced Data Security:",
      Number: "03",

      content:
        "Security is paramount with dTransfer's private cloud storage solution. Advanced encryption techniques ensure that sensitive documents remain accessible only to authorized personnel, safeguarding against potential data breaches or unauthorized access. This commitment to data security instills confidence in users, allowing them to trust dTransfer with their most valuable information.",
    },
    {
      title: "Eco-Friendly Practices:",
      Number: "04",
      content:
        "By transitioning to digital receipts with dTransfer, businesses can significantly reduce their paper consumption and contribute to environmental sustainability efforts. Eliminating paper waste not only reduces carbon footprint but also promotes a greener, more eco-friendly approach to document management. Through dTransfer, businesses can demonstrate their commitment to environmental responsibility while enjoying the features of streamlined operations.",
    },
    {
      title: "Predictive Analytics for Businesses:",
      Number: "05",

      content:
        "Harnessing the power of Predictive AI analytics, dTransfer provides invaluable insights into spending patterns and expense tracking. By leveraging data-driven decision-making, businesses can optimize budget allocations and resource management strategies for maximum efficiency. This predictive capability enables proactive planning and risk mitigation, empowering businesses to stay ahead of the curve in an ever-changing market landscape.",
    },
    {
      title: "Integration Capabilities:",
      Number: "06",

      content:
        "dTransfer seamlessly integrates with existing business systems, ensuring compatibility and minimal disruption to operations. This cohesive workflow enhances productivity and efficiency by eliminating silos and streamlining communication channels. By leveraging dTransfer's integration capabilities, businesses can unlock synergies between different departments and optimize their processes for maximum effectiveness.",
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderItems = items.map((item, index) => {
    const isActive = index === activeIndex;
    const icon = isActive ? "-" : "+";

    return (
      <div key={index} className={`accordion-item ${isActive ? "active" : ""}`}>
        <div className="accordion-title" onClick={() => onTitleClick(index)}>
          <span>
            {" "}
            <span className="acco-num">{item.Number}</span> {item.title}
          </span>

          <span className="accordion-icon">{icon}</span>
        </div>
        {isActive && <div className="accordion-content">{item.content}</div>}
      </div>
    );
  });

  return <div className="accordion">{renderItems}</div>;
};

export default AccorFeatDtrans;
