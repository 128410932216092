import React, { useState } from "react";
import "./Accordion.css";
const AccorFeatStorage = () => {
  const items = [
    {
      title: "Document Preservation:",
      Number: "01",
      content:
        "Safeguard important documents in a private cloud space, ensuring secure storage and protection from loss or damage. With Angel-App's Storage feature, users can rest assured that their critical documents are stored safely and are easily retrievable whenever needed.",
    },
    {
      title: "Flexible Storage Options:",
      Number: "02",

      content:
        "Tailor your storage needs with the option to purchase additional space, accommodating your growing digital document library. Whether you're a small business expanding its operations or an individual with a growing collection of digital files, Angel-App's flexible storage options ensure that you always have the space you need.",
    },
    {
      title: "Instant Accessibility:",
      Number: "03",

      content:
        "Access stored documents anytime, anywhere for quick retrieval and secure accessibility on the go. With Angel-App's Storage feature, users can access their documents from any device with an internet connection, allowing for seamless workflow and productivity, whether in the office or on the move.o.",
    },
    {
        title: "Document Versioning:",
        Number: "04",
  
        content:
          "Keep track of document changes with versioning capabilities for access to the latest versions. With Angel-App's versioning feature, users can easily track changes made to documents over time, ensuring that they always have access to the most up-to-date information.",
      },

      {
        title: "Encrypted Data Protection:",
        Number: "05",
  
        content:
          "Prioritize data security with advanced encryption, providing an additional layer of protection for sensitive information. Angel-App's encrypted data protection ensures that all stored documents are secure and protected from unauthorized access, giving users peace of mind knowing that their confidential information is safe.",
      },
      {
        title: "Audit Trails and Activity Logs:",
        Number: "06",
  
        content:
          "Monitor document activities through detailed audit trails, ensuring transparency in document management. Angel-App's activity logs provide users with insights into who has accessed or modified documents, allowing for greater accountability and control over document workflows..",
      }, 
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderItems = items.map((item, index) => {
    const isActive = index === activeIndex;
    const icon = isActive ? "-" : "+";

    return (
      <div key={index} className={`accordion-item ${isActive ? "active" : ""}`}>
        <div className="accordion-title" onClick={() => onTitleClick(index)}>
          <span>
            {" "}
            <span className="acco-num">{item.Number}</span> {item.title}
          </span>

          <span className="accordion-icon">{icon}</span>
        </div>
        {isActive && <div className="accordion-content">{item.content}</div>}
      </div>
    );
  });

  return <div className="accordion">{renderItems}</div>;
};

export default AccorFeatStorage;
