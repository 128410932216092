import React from "react";
import AboutBackground from "./images/teamwork.jpg";
import "./AboutUs.css";
// import SliderAbout from "./Slider/SliderAbout";
import Company from "./Company/Company";
function AboutUs() {
  const AboutFrontpage = {
    backgroundImage: `url(${AboutBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    minHeight: "90vh",
  };
  return (
    <>
      <div style={AboutFrontpage} className="AboutFrontPage">
        {/* <div className="AboutFrontRight">
          <h2 className="mobile-respo-Heading">
            <span className="Abtstrk">WHO</span> WE ARE
          </h2>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="verticle-line"></span>
            <div >
              <p style={{ fontSize: "16px" }} className="mobile-respo-para">
                We are Angel-Earth, where innovation meets sustainability!
                Founded by Rachelle Chand, an entrepreneurial powerhouse with a
                proven track record of building successful ventures, and Rav,
                our Executive Vice President who comes from a background in
                construction and finance, our mission is not just to solve
                immediate business challenges but to guide businesses toward
                sustainable practices. Join us on this transformative journey
                where innovation meets sustainability.
              </p>
            </div>
          </div>
        </div> */}
      </div>
      <div className="for-gradial-bg-div">
        {/* <div style={{ position: "relative", paddingTop: "1rem" }}>
          <div className="OurTeam-txt">
            <div className="about-headings-div">
              <h1>
                <span className="About-stroke">OUR </span>
                <span className="abtTxt"> TEAM</span>
              </h1>
              <p
                className="mobile-tab-denoter"
                style={{
                  position: "absolute",
                  left: "-2.2rem",
                  top: "5rem",
                  padding: "0.55rem",
                  backgroundColor: "#2F2C2C",
                  rotate: "-90deg",
                  fontWeight: "450",
                }}
              >
                OUR TEAM
              </p>
            </div>

            <div className="txt">
              <span className="verticle-lineour"></span>
              <div style={{ maxWidth: "38rem" }}>
                <p className="teamPara">
                  A Growing team having passion about the work we do. We being
                  great leadership and professional. Join us today!
                </p>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div id="right-slider">
          <SliderAbout />
        </div> */}

        <h2 className="mobile-respo-Heading" style={{ textAlign: "center" }}>
          <span className="Abtstrk">WHO</span> WE ARE
        </h2>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span className="verticle-line"></span>
          <div>
            <p
              className="mobile-respo-para text-para-dtrans"
              style={styles.aboutPara}
            >
              We are Angel-Earth, where innovation meets sustainability! Our
              Founder, an entrepreneurial powerhouse with a proven track record
              of building successful ventures, and our Executive Vice President
              who comes from a background in construction and finance, our
              mission is not just to solve immediate business challenges but to
              guide businesses toward sustainable practices. Join us on this
              transformative journey where innovation meets sustainability.
            </p>
          </div>
        </div>

        <Company />
      </div>
    </>
  );
}
const styles = {
  aboutPara: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
};
export default AboutUs;
