import React from "react";
import moneybackimg from "./Images/MoneyBackGuarntee.png";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import "./Moneyback.css";
function MoneyBack() {
  return (
    <>
      <div className="money-back-container">
        <p className="moneyback">MONEY-BACK</p>
        <div className="img-div">
          <img
            src={moneybackimg}
            className="img-moneyback"
            alt="MoneyBackIMG"
          />
        </div>
        <div className="Moneyback-text-container" style={{paddingRight:"8%"}}>
          <p className=" text-privacy-para" >
            <FaQuoteLeft
              style={{ color: "FAC14C", fontSize: "2rem" }}
              className="yellow-quotesymbol"
            />
            <br />
            Experience our services with confidence, thanks to our
            <span style={{ color: "#FAC14C" }}> 30-day money </span> -back
            Guarantee If for any reason our offerings don't meet your
            expectations, rest assured that you can request a full refund within
            the first month of your purchase. Your satisfaction is our priority,
            and we're committed to delivering a risk-free and rewarding
            experience.
            <FaQuoteRight
              style={{
                color: "FAC14C",
                fontSize: "2rem",
                position: "absolute",
                bottom: "-12px",
              }}
              className="yellow-quotesymbol"
            />
            
          </p>
        </div>
      </div>
    </>
  );
}

export default MoneyBack;
