import React from "react";
import "./WhyUsClick.css";
// import Streamlined from "./Images/Streamlined.png";
// import Securitylogo from "./Images/Data-Security.png";
// import flexibleimage from "./Images/Flexible-integration.png";
function Why() {

  return (
    <>
      {/* <div id="why" style={{ position: "relative" }}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "8rem",
              }}
              className="why-holderdiv"
            >
              <div>
                <div className="why-respo-heading">
                  <p
                    style={{ color: "#7E7B7B", fontWeight: "bold" }}
                    className="why-nums"
                  >
                    01
                  </p>

                  <p className="heading-why">Streamlined Workflows</p>
                </div>

                <p className="whydata">
                  Simplify your daily operations with our intuitive SAAS
                  solutions,
                  <br />
                  designed to enhance productivity and efficiency.
                </p>
              </div>

              <div className="img-container-flexible">
                <img src={Streamlined} className="why-images" />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "8.25rem",
            alignItems: "center",
          }}
          className="col-revs-why why-holderdiv"
        >
          <div className="securityimg-container">
            <img src={Securitylogo} className="Security-img" />
          </div>
          <div>
            <div className="why-respo-heading">
              <p
                style={{ color: "#7E7B7B", fontWeight: "bold" }}
                className="why-nums"
              >
                02
              </p>
              <p className="heading-why" style={{ margin: 0 }}>
                Data Security
              </p>
            </div>
            <p className="whydata">
              Rest easy knowing that your sensitive data is protected with
              <br /> state-of-the-art security measures and robust encryption.{" "}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "5rem",
            marginTop: "1rem",
          }}
          className="why-holderdiv"
        >
          <div>
            <div className="why-respo-heading">
              <p
                style={{ color: "#7E7B7B", fontWeight: "bold" }}
                className="why-nums"
              >
                03
              </p>

              <p className="heading-why">Flexible Integraion</p>
            </div>
            <p className="whydata">
              Seamlessly integrate our SAAS Products with your existing tools
              <br />
              and systems to maximize their impact
            </p>
          </div>
          <div>
            <img
              src={flexibleimage}
              style={{ maxHeight: "300px" }}
              className="integration-img"
            />
          </div>
        </div>
      </div> */}

      <div  className="why-angel-app-container">
        <h2 style={{textAlign:"center"}}>Why Choose Us:</h2>

        <p className="text-para-dtrans">
          Angel-Earth is a goal-oriented company that looks at the scope beyond
          profits. Fundamentally, we want both businesses and consumers to reach
          a more sustainable future with less pollution and paper waste. Through
          this goal, Angel-App was developed to help digitize the future of
          commerce. By digitizing commerce, we remove not just the waste and
          pollution outcomes from commerce but also help businesses and
          consumers by removing the need for paper coupons. Consumers simply
          receive the coupons they would have otherwise received in the mail
          through our proximity-based push notifications. These proximity
          promotions not only help consumers save money but also provide
          exposure for businesses that may fall under the radar in traditional
          marketing spaces. Foundationally, Angel-Earth wants both consumers and
          businesses to flourish and overcome the challenges they face in our
          current times. By removing overhead costs for businesses and ensuring
          consumers receive the opportunities for deals to save money both sides
          of the commerce exchange features.
        </p>
      </div>
    </>
  );
}

export default Why;
