import { React, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import AccorFeatCoupon from "../../services/accordion/AccorFeatCoupon";
import AccorGrowCoupon from "../../services/accordion/AccorGrowCoupon";
import Information from "../../services/information/Information";
import Servicelists from "../../services/servicelists/Servicelists";
import "./NavigateComp.css";
import Coupon from "../../services/servicerender/ServiceComp/Coupon";
import { useNavigate, useLocation } from "react-router-dom";
function NavigateCoupon() {
  const navigate = useNavigate();
  const location = useLocation();
  const couponRef = useRef(null);

  const scrollToCoupon = () => {
    if (location.pathname === "/services/coupon" && couponRef.current) {
      window.scrollTo({
        top: couponRef.current.offsetTop,
        behavior: "smooth",
      });
    } else {
      navigate("/services/coupon");
    }
  };

  useEffect(() => {
    if (location.pathname === "/services/coupon" && couponRef.current) {
      window.scrollTo({
        top: couponRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, [location.pathname]);

  const pageTitle =
    "Coupon - Boost engagement with targeted discounts and promotions";
  const pageDescription =
    "Explore the features and growth opportunities with Coupons. Drive customer engagement and sales through targeted discount promotions.";
  const brandLogoUrl = `${process.env.PUBLIC_URL}/A-ELogo.png`;
  const cdnImageUrl = `https://images.pexels.com/photos/8171195/pexels-photo-8171195.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={brandLogoUrl} />
        <meta
          property="og:url"
          content="https://angel-earth.com/services/coupon"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={cdnImageUrl} />
      </Helmet>
      <Servicelists scrollToCoupon={scrollToCoupon} />

      <div ref={couponRef}>
        <Coupon />
      </div>

      <div className="Services-grid">
        <div>
          <p className="Accor-head">FEATURES</p>
        </div>
        <div>
          <AccorFeatCoupon />
        </div>
      </div>

      <div className="Services-grid">
        <div>
          <p className="Accor-head">
            HERE’S HOW WE
            <br /> HELP YOU GROW
          </p>
        </div>
        <div>
          <AccorGrowCoupon />
        </div>
      </div>

      <Information />
    </>
  );
}

export default NavigateCoupon;
