import React, { useState } from "react";
import "./Accordion.css";
const AccorGrowDtrans = () => {
  const items = [
    {
      title: "Cost-Effective Management:",
      Number: "01",
      content:
        "Transitioning to dTransfer offers a cost-effective alternative to traditional document management practices. By eliminating the need for physical storage and manual organization, businesses can significantly reduce overhead costs associated with paper-based processes. This cost-saving measure not only improves the bottom line but also frees up resources to invest in other areas of business growth. ",
    },
    {
      title: "Collaborative Efficiency:",
      Number: "02",

      content:
        "dTransfer's centralized platform fosters collaborative efficiency by providing a shared workspace for document management. Teams can collaborate in real-time, share feedback, and accelerate decision-making processes. This collaborative approach enhances teamwork and ensures that everyone is working towards common goals, ultimately driving business success",
    },
    {
      title: "Streamlined Workflows:",
      Number: "03",

      content:
        "By optimizing internal workflows, dTransfer helps businesses operate more efficiently and effectively. By reducing paperwork and automating repetitive tasks, dTransfer enables teams to focus on high-value activities that drive business growth. This streamlining of processes leads to increased productivity, faster turnaround times, and ultimately, better business outcomes.",
    },
    {
      title: "Expense Tracking Insights",
      Number: "04",
      content:
        "Through Predictive AI analytics, dTransfer provides valuable insights into expense tracking, helping businesses gain a deeper understanding of their spending habits. By identifying patterns and trends, businesses can make informed decisions about budget allocation and resource management. This personalized approach to expense tracking enables businesses to optimize their financial strategies and achieve greater profitability.",
    },
    {
      title: "Aligns With Other Angel-App Products to Maximize Efficiency:",
      Number: "05",

      content:
        "By using dTransfer, businesses can seamlessly integrate with other innovative features within the Angel-App ecosystem. This integration maximizes efficiency and unlocks additional capabilities that further enhance business growth and ROI. Whether it's leveraging data insights or streamlining workflows, dTransfer aligns with other Angel App products to provide a comprehensive solution that meets the evolving needs of businesses."
    },
   
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderItems = items.map((item, index) => {
    const isActive = index === activeIndex;
    const icon = isActive ? "-" : "+";

    return (
      <div key={index} className={`accordion-item ${isActive ? "active" : ""}`}>
        <div className="accordion-title" onClick={() => onTitleClick(index)}>
          <span>
            {" "}
            <span className="acco-num">{item.Number}</span> {item.title}
          </span>

          <span className="accordion-icon">{icon}</span>
        </div>
        {isActive && <div className="accordion-content">{item.content}</div>}
      </div>
    );
  });

  return <div className="accordion">{renderItems}</div>;
};

export default AccorGrowDtrans;
