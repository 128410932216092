import React, { useState } from "react";
import "./Accordion.css";
const AccorFeatPushRad = () => {
  const items = [
    {
      title: "Geographic Precision:",
      Number: "01",
      content:
        "Push Radius offers unprecedented geographic precision, empowering businesses to select exposure radii ranging from 1km to 10km. This granular control ensures that promotional messages are delivered to the most relevant audience, increasing the likelihood of conversion and customer satisfaction.",
    },
    {
      title: "Coupon and Flyer Customization:",
      Number: "02",

      content:
        "Tailor the reach of Coupons and Flyers with Push Radius by selecting exposure radii that align with your marketing objectives. Whether targeting specific neighborhoods or broader citywide events, businesses can ensure their promotional materials reach the right audience for maximum impact.",
    },
    {
      title: "Strategic 1 Day Campaigns:",
      Number: "03",

      content:
        "Enhance the effectiveness of time-sensitive 1 Day Campaigns by strategically defining campaign reach with Push Radius. By choosing radius options that align with campaign objectives, businesses can increase visibility and drive urgency among customers, leading to higher conversion rates.",
    },
   
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderItems = items.map((item, index) => {
    const isActive = index === activeIndex;
    const icon = isActive ? "-" : "+";

    return (
      <div key={index} className={`accordion-item ${isActive ? "active" : ""}`}>
        <div className="accordion-title" onClick={() => onTitleClick(index)}>
          <span>
            {" "}
            <span className="acco-num">{item.Number}</span> {item.title}
          </span>

          <span className="accordion-icon">{icon}</span>
        </div>
        {isActive && <div className="accordion-content">{item.content}</div>}
      </div>
    );
  });

  return <div className="accordion">{renderItems}</div>;
};

export default AccorFeatPushRad;
