import React, { useState } from "react";
import "./Accordion.css";
const AccorGrowPushNoti = () => {
  const items = [
    {
      title: "Empowering Business Control:",
      Number: "01",
      content:
        "Empower businesses with control over their promotional reach. With Angel-App's Radius-Based Exposure feature, businesses can align their promotional efforts with strategic goals, ensuring maximum impact and ROI.",
    },
    {
      title: "Enhanced Customer Retention:",
      Number: "02",

      content:
        "Boost customer retention by delivering targeted alerts about loyalty programs, exclusive discounts, and special offers. By keeping customers informed and engaged, businesses can strengthen relationships and encourage repeat purchases",
    },
    {
      title: "Dynamic Marketing Strategies:",
      Number: "03",

      content:
        "Implement dynamic marketing strategies that adapt to different contexts and customer preferences. With Angel-App's flexible features, businesses can create varied campaigns tailored to specific locations, events, or customer segments, ensuring relevance and effectiveness.",
    },
    {
      title: "Cost-Efficient Advertising:",
      Number: "04",
      content:
        "Optimize advertising costs with efficient radius-based exposure. By targeting specific geographic areas where your audience is most likely to be, businesses can minimize wasteful spending and maximize the impact of their marketing budget.",
    },
    {
      title: "Strategic Collaboration with Angel-App:",
      Number: "05",

      content:
        "Collaborate strategically with Angel-App to unlock the full potential of its features and elevate your business's promotional impact. From personalized guidance on campaign optimization to ongoing support and updates, Angel-App partners with businesses to drive growth and success in today's competitive market landscape."
    },
   
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderItems = items.map((item, index) => {
    const isActive = index === activeIndex;
    const icon = isActive ? "-" : "+";

    return (
      <div key={index} className={`accordion-item ${isActive ? "active" : ""}`}>
        <div className="accordion-title" onClick={() => onTitleClick(index)}>
          <span>
            {" "}
            <span className="acco-num">{item.Number}</span> {item.title}
          </span>

          <span className="accordion-icon">{icon}</span>
        </div>
        {isActive && <div className="accordion-content">{item.content}</div>}
      </div>
    );
  });

  return <div className="accordion">{renderItems}</div>;
};

export default AccorGrowPushNoti;
