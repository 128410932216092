import React from "react";
import "./ServiceComp.css";
import { useNavigate } from "react-router-dom";
function Storage() {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setTimeout(() => {
      navigate("/why-angel-app");
    }, 100);
  };
  return (
    <>
      <div className="Dtransfer-body">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-headdiv">
            <p className="headData">STORAGE</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="Dtransfer">
              {/* <div>
                <span className="verticle-line"></span>
              </div> */}
              <div className="DtransTxt">
                <p>
                  Preserve, organize, and secure your digital documents
                  effortlessly with Angel-App's Storage feature. Welcome to a
                  smarter way of managing your documents! With Angel-App's
                  intuitive Storage feature, businesses and individuals alike
                  can streamline their document management processes, ensuring
                  easy access, enhanced security, and seamless organization for
                  all their digital files.
                </p>
              </div>
            </div>

            <div className="Dtransfer-btn-container">
              <button
                className="D-transform-btn"
                onClick={handleButtonClick}
                style={{ cursor: "pointer" }}
              >
                Why Angel-App
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Storage;
