import React, { useState } from "react";
import { Helmet } from "react-helmet";
import DtransferPricing from "../../Components/NavigatePricingComp/DtransferPricing/DtransferPricing";
import OneDayCampaign from "../../Components/Pricing/onedaycamp/OneDayCampaign";
import FlyerPricing from "../../Components/NavigatePricingComp/Flyerpricing/FlyerPricing";
import Couponpricing from "../../Components/NavigatePricingComp/CouponPricing/Couponpricing";
import PricingCardsStorage from "../../Components/Pricing/PricingCards/PricingCardsStorage";
import "./PricingPage.css";

const PricingPage = () => {
  const data = ["Flyer", "Coupon", "Storage", "dTransfer", "One Day Campaign"];

  const [currentInd, setCurrentInd] = useState(0);

  const renderComponent = () => {
    switch (currentInd) {
      case 0:
        return <FlyerPricing />;

      case 1:
        return <Couponpricing />;

      case 2:
        return <PricingCardsStorage />;

      case 3:
        return <DtransferPricing />;

      case 4:
        return <OneDayCampaign />;

      default:
        return null;
    }
  };

  const pageTitle = `${data[currentInd]} Pricing - Angel Earth`;
  const pageDescription = `Explore the pricing details and features of ${data[currentInd]} offered by Angel Earth. Choose the best plan that suits your business needs.`;

  const brandLogoUrl = `${process.env.PUBLIC_URL}/A-ELogo.png`;
  const cdnImageUrl =
    "https://images.pexels.com/photos/4308102/pexels-photo-4308102.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";

  return (
    <div className="Pricing1">
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={brandLogoUrl} />
        <meta property="og:url" content="https://angel-earth.com/pricing" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={cdnImageUrl} />
      </Helmet>

      <div className="SelectCategoryDiv" style={styles.selectCategoryDiv}>
        {data.map((val, index) => (
          <CategoryItem
            key={index}
            val={val}
            index={index}
            setCurrentInd={setCurrentInd}
            currentInd={currentInd}
          />
        ))}
      </div>

      <div id="Pricing-Components">{renderComponent()}</div>
    </div>
  );
};

const CategoryItem = ({ val, index, setCurrentInd, currentInd }) => {
  return (
    <p
      style={{
        fontSize: "1em",
        color: "white",
        opacity: currentInd === index ? "1" : "0.8",
        margin: "2em 0.7em",
        borderBottom: currentInd === index ? "1px solid white" : null,
        cursor: "pointer",
        fontFamily: "Arial",
        letterSpacing: "0.1em",
      }}
      onClick={() => {
        setCurrentInd(index);
      }}
    >
      {val}
    </p>
  );
};

const styles = {
  selectCategoryDiv: {
    display: "flex",
    alignSelf: "flex-end",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    marginTop: "-1rem",
  },
  DtransferBtn: {
    width: "6.3em",
    height: "2.2em",
    borderRadius: "1.2em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.9em",
    color: "#00C7FF",
    border: "#00C7FF solid 1px",
  },
  categoryLink: {
    textDecoration: "none",
  },
};

export default PricingPage;
