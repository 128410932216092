import { React, useEffect, useRef } from "react";

import { Helmet } from "react-helmet";
import AccorFeatStorage from "../../services/accordion/AccorFeatStorage";
import AccorGrowStorage from "../../services/accordion/AccorGrowStorage";
import Information from "../../services/information/Information";
import Servicelists from "../../services/servicelists/Servicelists";
import "./NavigateComp.css";
import Storage from "../../services/servicerender/ServiceComp/Storage";
import { useNavigate, useLocation } from "react-router-dom";
function NavigateStorage() {
  const navigate = useNavigate();
  const location = useLocation();
  const storageref = useRef(null);
  const scrolltoSTorage = () => {
    if (location.pathname === "/services/storage" && storageref.current) {
      window.scrollTo({
        top: storageref.current.offsetTop,
        behavior: "smooth",
      });
    } else {
      navigate("/services/storage");
    }
  };
  useEffect(() => {
    if (location.pathname === "/services/storage" && storageref.current) {
      window.scrollTo({
        top: storageref.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, [location.pathname]);
  const pageTitle = "Storage - Secure and scalable digital storage solutions";
  const pageDescription =
    "Discover the features and growth opportunities with Storage solutions. Securely store and manage your digital assets, ensuring accessibility and reliability.";
  const brandLogoUrl = `${process.env.PUBLIC_URL}/A-ELogo.png`;
  const cdnImageUrl =
    "https://images.pexels.com/photos/3184345/pexels-photo-3184345.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={brandLogoUrl} />
        <meta
          property="og:url"
          content="https://angel-earth.com/services/storage"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={cdnImageUrl} />
      </Helmet>
      <Servicelists scrolltoSTorage={scrolltoSTorage} />
      <div ref={storageref}>
        <Storage />
      </div>
      <div className="Services-grid">
        <div>
          <p className="Accor-head">FEATURES</p>
        </div>
        <div>
          <AccorFeatStorage />
        </div>
      </div>

      <div className="Services-grid">
        <div>
          <p className="Accor-head">
            HERE’S HOW WE
            <br /> HELP YOU GROW
          </p>
        </div>
        <div>
          <AccorGrowStorage />
        </div>
      </div>

      <Information />
    </>
  );
}
export default NavigateStorage;
