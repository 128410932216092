import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        height: "70vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#000",
        padding: "2rem",
      }}
    >
      <ErrorOutlineIcon sx={{ fontSize: "8rem", color: "#389E47" }} />{" "}
      <Typography
        variant="h1"
        sx={{ fontSize: "5rem", fontWeight: 700, color: "#717171" }}
      >
        404
      </Typography>
      <Typography
        variant="h6"
        sx={{
          fontSize: "1.5rem",
          color: "#717171",
          mb: 2,
          textAlign: "center",
        }}
      >
        Oops! The page you're looking for doesn't exist.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          fontSize: "1.1rem",
          color: "#717171",
          textAlign: "center",
          mb: 4,
        }}
      >
        It might have been moved, renamed, or deleted. Let's get you back on
        track!
      </Typography>
      <Button
        variant="contained"
        size="large"
        onClick={() => {
          navigate("/");
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }}
        sx={{
          backgroundColor: "#389E47",
          "&:hover": { backgroundColor: "#389E47" },
          padding: "0.75rem 2rem",
          fontSize: "1rem",
          color: "#ffffffde",
        }}
      >
        Go Home
      </Button>
    </Box>
  );
};

export default NotFoundPage;
