import React from "react";
import "./ServiceComp.css";
import { useNavigate } from "react-router-dom";
function Coupon() {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    window.scrollTo(0, 0);

    setTimeout(() => {
      navigate("/why-angel-app");
    }, 100);
  };
  return (
    <>
      <div className="Dtransfer-body">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-headdiv">
            <p className="headData">COUPON</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="Dtransfer">
              {/* <div>
                <span className="verticle-line"></span>
              </div> */}
              <div className="DtransTxt">
                <p>
                  Angel-App's coupon platform empowers businesses with targeted
                  promotions, enhancing customer loyalty and driving sales. By
                  leveraging advanced features and strategic insights,
                  businesses can create personalized offers that resonate with
                  their target audience, ultimately boosting brand visibility
                  and fostering long-term customer relationships
                </p>
              </div>
            </div>

            <div className="Dtransfer-btn-container">
              <button
                className="D-transform-btn"
                onClick={handleButtonClick}
                style={{ cursor: "pointer" }}
              >
                Why Angel-App
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Coupon;
