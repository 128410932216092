import { React, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import DTransfer from "../../services/servicerender/ServiceComp/ServiceCompDTrans";
import AccorFeatDtrans from "../../services/accordion/AccorFeatDtrans";
import AccorGrowDtrans from "../../services/accordion/AccorGrowDtrans";
import Information from "../../services/information/Information";
import Servicelists from "../../services/servicelists/Servicelists";
import "./NavigateComp.css";
import { useNavigate, useLocation } from "react-router-dom";

function NavigateDTrans() {
  const navigate = useNavigate();
  const location = useLocation();
  const pageTitle = "DTransfer - Services for Digital Transfer";
  const pageDescription =
    "Explore the features and growth aspects of DTransfer. Learn how DTransfer helps businesses manage digital transfers effectively.";
  const brandLogoUrl = `${process.env.PUBLIC_URL}/A-ELogo.png`;
  const cdnImageUrl =
    "https://images.pexels.com/photos/8171195/pexels-photo-8171195.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";
  const dTransferRef = useRef(null);

  // Function to scroll to DTransfer component
  const scrollToDTransfer = () => {
    if (location.pathname === "/services/dtransfer" && dTransferRef.current) {
      window.scrollTo({
        top: dTransferRef.current.offsetTop,
        behavior: "smooth",
      });
    } else {
      navigate("/services/dtransfer");
    }
  };
  useEffect(() => {
    if (location.pathname === "/services/dtransfer" && dTransferRef.current) {
      window.scrollTo({
        top: dTransferRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, [location.pathname]);

  return (
    <div>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={brandLogoUrl} />
        <meta
          property="og:url"
          content="https://angel-earth.com/services/dtransfer"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={cdnImageUrl} />
      </Helmet>
      <Servicelists scrollToDTransfer={scrollToDTransfer} />
      <div ref={dTransferRef}>
        <DTransfer />
      </div>

      <div className="Services-grid">
        <div>
          <p className="Accor-head">FEATURES</p>
        </div>
        <div>
          <AccorFeatDtrans />
        </div>
      </div>

      <div className="Services-grid">
        <div>
          <p className="Accor-head">
            HERE’S HOW WE
            <br /> HELP YOU GROW
          </p>
        </div>
        <div>
          <AccorGrowDtrans />
        </div>
      </div>

      <Information />
    </div>
  );
}

export default NavigateDTrans;
