import React from 'react';
import { TwitterShareButton } from 'react-share';

const ShareButton = () => {
  const shareUrl = 'https://www.angel-earth.ca/'; 
  const title = 'Check out this awesome website!';

  return (
    <TwitterShareButton url={shareUrl} title={title}>
      <button className="share-btn">Share on X</button>
    </TwitterShareButton>
  );
};

export default ShareButton;
