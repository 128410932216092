const Dataofslider = [
  {
    image:
      "https://cdn.pixabay.com/photo/2020/07/08/04/12/work-5382501_1280.jpg",
  },
  {
    image:
      "https://cdn.pixabay.com/photo/2015/01/09/11/09/meeting-594091_1280.jpg",
  },
  {
    image:
      "https://cdn.pixabay.com/photo/2017/01/14/10/56/people-1979261_1280.jpg",
  },
  {
    image:
      "https://cdn.pixabay.com/photo/2015/01/08/18/27/startup-593341_1280.jpg",
  },
  {
    image:
      "https://cdn.pixabay.com/photo/2015/01/09/11/09/meeting-594091_1280.jpg",
  },
  {
    image:
      "https://cdn.pixabay.com/photo/2015/01/08/18/11/laptops-593296_1280.jpg",
  },
  {
    image:
      "https://cdn.pixabay.com/photo/2018/03/10/12/00/teamwork-3213924_1280.jpg",
  },
];

export default Dataofslider;
