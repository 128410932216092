import React, { useState } from "react";
import "./Accordion.css";
const AccordionFeatFlyer = () => {
  const items = [
    {
      title: " Visually Appealing Content:",
      Number: "01",
      content:
        "Craft visually appealing digital flyers that captivate audiences with dynamic content. By incorporating eye-catching visuals, engaging graphics, and compelling messaging, businesses can capture the attention of their target audience and stand out in a crowded marketplace"
    },
    {
      title: "Targeted Promotions",
      Number: "02",

      content:
        "Design targeted promotions within digital flyers, maximizing impact and driving customer engagement. With Angel-App's flyer platform, businesses can tailor promotions to specific customer segments, ensuring that each flyer resonates with its intended audience and delivers maximum value.",
    },
    {
      title: "Predictive AI Analytics:",
      Number: "03",

      content:
        "Utilize Predictive AI analytics to gain valuable insights into customer interactions, refining promotional strategies for better results. By analyzing customer behavior and engagement patterns, businesses can optimize their flyer content, timing, and targeting to drive higher conversion rates and ROI.",
    },
    {
      title: "dTransfer Integration",
      Number: "04",
      content:
        "Seamlessly integrate flyer data with dTransfer for a comprehensive shopping experience, building lasting relationships beyond transactions. By connecting flyer interactions with dTransfer, businesses can track customer preferences, purchase history, and engagement metrics, enabling personalized follow-up and ongoing communication.",
    },
    {
      title: "Eco-Friendly Initiatives:",
      Number: "05",

      content:
        "Align with Angel-App's sustainability commitment, contributing to eco-friendly practices by reducing paper waste with digital flyers. By transitioning to digital flyers, businesses can minimize their environmental footprint while also saving on printing and distribution costs, demonstrating their dedication to corporate social responsibility.",
    },
   
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderItems = items.map((item, index) => {
    const isActive = index === activeIndex;
    const icon = isActive ? "-" : "+";

    return (
      <div key={index} className={`accordion-item ${isActive ? "active" : ""}`}>
        <div className="accordion-title" onClick={() => onTitleClick(index)}>
          <span>
            {" "}
            <span className="acco-num">{item.Number}</span> {item.title}
          </span>

          <span className="accordion-icon">{icon}</span>
        </div>
        {isActive && <div className="accordion-content">{item.content}</div>}
      </div>
    );
  });

  return <div className="accordion">{renderItems}</div>;
};

export default AccordionFeatFlyer;
