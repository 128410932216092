import React, { useState } from "react";
import "./Accordion.css";
const AccorGrowFlyer = () => {
  const items = [
    {
      title: " Cost-Effective Solution:",
      Number: "01",
      content:
        "Maximize budgets with visually appealing and targeted digital flyers, effectively capturing the attention of the target audience. By leveraging Angel-App's digital flyer platform, businesses can create high-impact promotional materials without the need for costly printing or distribution, ensuring a cost-effective solution for reaching their audience."
    },
    {
      title: "Enhanced Customer Engagement:",
      Number: "02",

      content:
        "Elevate customer engagement with visually appealing and interactive digital flyers that convey promotions effectively. With interactive features such as clickable links, embedded videos, and personalized recommendations, businesses can create immersive experiences that keep customers engaged and excited about their brand.",
    },
    {
      title: "Data-Driven Flyer Strategies:",
      Number: "03",

      content:
        "Leverage Predictive AI insights to refine flyer strategies based on customer behaviors, ensuring maximum impact. By analyzing flyer engagement metrics, businesses can identify trends, preferences, and opportunities for optimization, allowing them to fine-tune their flyer content and delivery for better results.",
    },
    {
      title: "Brand Visibility:",
      Number: "04",
      content:
        "Increase brand visibility by showcasing promotions in a visually appealing manner, enhancing brand image and market presence. With Angel-App's digital flyer platform, businesses can create professional-looking flyers that reflect their brand identity and communicate their value proposition effectively, helping to strengthen brand awareness and recognition among customers.",
    },
    {
      title: "Collaborate for Targeted Success:",
      Number: "05",

      content:
        "Engage with Angel-App to harness the full potential of Flyers with Push Radius, Push notifications, Coupons, 1 Day Campaigns, and Storage. Implement precise and effective promotional strategies, enhancing brand visibility, and driving customer engagement. Partner with Angel-App for a strategic approach to your promotional endeavors, leveraging advanced features and insights to achieve targeted success.",
    },
   
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderItems = items.map((item, index) => {
    const isActive = index === activeIndex;
    const icon = isActive ? "-" : "+";

    return (
      <div key={index} className={`accordion-item ${isActive ? "active" : ""}`}>
        <div className="accordion-title" onClick={() => onTitleClick(index)}>
          <span>
            {" "}
            <span className="acco-num">{item.Number}</span> {item.title}
          </span>

          <span className="accordion-icon">{icon}</span>
        </div>
        {isActive && <div className="accordion-content">{item.content}</div>}
      </div>
    );
  });

  return <div className="accordion">{renderItems}</div>;
};

export default AccorGrowFlyer;
