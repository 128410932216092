import { React, useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import TechnologyAlbertaIMG from "../../assets/TechnologyAlberta.png";
import MasterCardImg from "./MarqueeImages/MasterCard.png";
import ApplePayImg from "./MarqueeImages/ApplePay.png";
import AlbertaImg from "./MarqueeImages/Alberta.png";
import ShopifyImg from "./MarqueeImages/Shopify.png";
import StripeImg from "./MarqueeImages/StripeIMG.png";
import GpayImg from "./MarqueeImages/Gpay.png";
import Awsimg from "./MarqueeImages/AwsLogoSvg.svg";
import "../Dtransfer/DtransferFooter/Footer.css";
const MarqueeComp = () => {
  const [speed, setSpeed] = useState(150);
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSpeed(100);
      } else {
        setSpeed(150);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Marquee direction="left" speed={speed}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <div>
          <p
            className="moving-text stretch-text"
            style={{
              fontWeight: "bold",
            }}
          >
            WELCOME TO <span className="gradient-text">FUTURE</span> OF PAYMENTS
          </p>
        </div>
        <div className="footer-imgContainer">
          <img
            src={ApplePayImg}
            alt="Apple-Pay"
            srcSet=""
            // width={80}
            height={120}
            className="FooterImgLOGOSs"
            style={{ marginLeft: "100vw" }}
          />
          <img
            src={GpayImg}
            alt="G-Pay-Logo"
            srcSet=""
            width={80}
            height={80}
            className="FooterImgLOGOS"
            style={{ marginLeft: "10px" }}
          />
          <img
            src={Awsimg}
            alt="AWS-Logo"
            srcSet=""
            width={80}
            height={120}
            // className="FooterImgLOGOS"
            style={{ marginLeft: "10px" }}
          />
          <img
            src={ShopifyImg}
            alt="Shopify-Logo"
            srcSet=""
            width={80}
            height={80}
            className="FooterImgLOGOS"
            style={{ marginLeft: "10px" }}
          />
          <img
            src={StripeImg}
            alt="Stripe-Logo"
            srcSet=""
            width={80}
            height={80}
            className="FooterImgLOGOS"
            style={{ marginLeft: "10px" }}
          />
          <img
            src={MasterCardImg}
            alt="MasterCard-Logo"
            srcSet=""
            width={80}
            height={80}
            className="FooterImgLOGOS"
            style={{ marginLeft: "10px" }}
          />
          <img
            src={AlbertaImg}
            alt="Alberta-Innovates-Logo"
            srcSet=""
            width={95}
            height={40}
          />
          <img
            src={TechnologyAlbertaIMG}
            alt="Technology-Alberta-Innovates-IMG"
            srcSet=""
            width={95}
            height={50}
            style={{ marginRight: "100vw" }}
          />
        </div>
      </div>
    </Marquee>
  );
};

export default MarqueeComp;
