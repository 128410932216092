import React from "react";
import { Helmet } from "react-helmet";
import "./LegalPages.css";
function PrivacyPolicy() {
  const brandLogoUrl = `${process.env.PUBLIC_URL}/A-ELogo.png`;
  const cdnImageUrl =
    "https://images.pexels.com/photos/3184339/pexels-photo-3184339.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";

  return (
    <>
      <Helmet>
        <title>Privacy Policy - Angel Earth</title>
        <meta
          name="description"
          content="Learn about how Angel Earth protects user privacy with our comprehensive Privacy Policy, covering data collection, usage, and security measures."
        />
        <meta property="og:title" content="Privacy Policy - Angel Earth" />
        <meta
          property="og:description"
          content="Learn about how Angel Earth protects user privacy with our comprehensive Privacy Policy, covering data collection, usage, and security measures."
        />
        <meta property="og:image" content={brandLogoUrl} />
        <meta
          property="og:url"
          content="https://angel-earth.com/privacy-policy"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Privacy Policy - Angel Earth" />
        <meta
          name="twitter:description"
          content="Learn about how Angel Earth protects user privacy with our comprehensive Privacy Policy, covering data collection, usage, and security measures."
        />
        <meta name="twitter:image" content={cdnImageUrl} />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="the-main-container">
        <div>
          <h1 style={{ textAlign: "center" }} className="heading-contest">
            Privacy Policy
          </h1>
          <p>
            <strong>Effective date:</strong> 5th day of March, 2022
          </p>
          <p>
            <strong> Updated:</strong> 1st day of January, 2024
          </p>
          <p>
            <strong>Type of business:</strong> E-commerce
          </p>
          <p>
            <strong>Website:</strong>
            <a href="www.angel-earth.com">[www.angel-earth.com]</a> &nbsp;
            <a href="http://www.angel-earth.com">
              (http://www.angel-earth.com)
            </a>
          </p>
          <p>
            <strong>Owner and Operator:</strong> Angel-Earth Corporation
          </p>
          <p>
            <strong>Contact:</strong>
            <a href="mailto:welcome@angel-earth.com">welcome@angel-earth.com</a>
          </p>
        </div>
        <div>
          <strong>Purpose </strong>
          <p>
            This Privacy Policy outlines how Angel-Earth Corporation collects,
            uses, and protects user data on both web and mobile platforms. We
            are a Canadian-based business and adhere to relevant privacy laws
            and regulations, including the General Data Protection Regulation
            (GDPR) for users in the European Union and the United Kingdom.
          </p>
        </div>

        <div>
          <strong> User Consent </strong>
          <p>
            By utilizing our services, users explicitly agree to the terms
            detailed in this Privacy Policy.
          </p>
          <p>
            Users can withdraw their consent by sending an email to
            welcome@angel-earth.com after deleting their profile under their
            account settings.
          </p>
        </div>
        <div>
          <strong>Legal Basis for Processing </strong>
          <p>
            We collect and process personal data based on user consent, the
            necessity for contractual obligations, compliance with legal
            obligations, protection of vital interests, consent, and pursuit of
            legitimate interests on both web and mobile platforms
          </p>
          <strong> Personal Data We Collect </strong>
          <p>Data Collected Automatically</p>
        </div>
        <div>
          <strong> Web Platform: </strong>
          <strong>
            When using our services on the web, we automatically collect and
            store:
          </strong>
          <ol className="list-items-data">
            <li>
              <strong>IP address:</strong> to identify the location of users.
            </li>
            <li>
              <strong>Location data:</strong> to enhance user experience and
              provide location-specific services.
            </li>
            <li>
              <strong>Clickstream data:</strong> to analyze user interactions
              with our platform.
            </li>

            <li>
              <strong>Content viewed:</strong>
              to personalize user experience.
            </li>
          </ol>
        </div>
        <div>
          <strong>Mobile Platform: </strong>
          <strong>In addition to the above, the mobile app collects:</strong>
          <ol className="list-items-data">
            <li>
              <strong>Location Service for dtransfer:</strong>
              <span>
                To facilitate data transfer when scanning QR codes, improving
                efficiency in information sharing
              </span>
            </li>

            <li>
              <strong> Access to photos and files:</strong>
              <span>
                Users have the option to upload photos and files to the Angel
                app, choosing to save them on their private cloud
              </span>
            </li>

            <li>
              <strong> Access to Camera: </strong>
              <span>
                The camera is used for scanning documents and receipts, allowing
                users to save them on their private cloud.
              </span>
            </li>

            <li>
              <strong> Contacts Permissions: </strong>
              <span>
                Users can choose to allow the app to access their contacts to
                identify friends using the Angel-App, facilitating file-sharing
                within the app.
              </span>
            </li>
          </ol>
        </div>
        <div>
          <strong>Data Collected in a Non-Automatic Way </strong>
          <strong>
            We collect the following data when users perform certain functions:
          </strong>
          <ol className="list-items-data">
            <li>
              <strong> Registration Data: </strong>
              <span>
                First name, last name, email address, city, phone number, and
                information about whether the account is for personal or
                business use.
              </span>
            </li>
          </ol>
          <strong>Use of Personal Data </strong>
          <p>
            Data collected is used for the following purposes on both web and
            mobile platforms:
          </p>

          <ol className="list-items-data">
            <li>
              <strong> Statistics and Analytics: </strong>
              <span>
                Utilizing aggregated and anonymized data to analyze user
                behaviour and improve our services.
              </span>
            </li>

            <li>
              <strong> Business Model B2B and B2C: </strong>
              <span>
                Tailoring our services to meet the needs of both business and
                individual users.
              </span>
            </li>

            <li>
              <strong> Granting Access and Communication: </strong>
              <span>
                Facilitating seamless communication between users and granting
                access to relevant features and content.
              </span>
            </li>
          </ol>
        </div>
        <div>
          <strong> User Rights </strong>
          <strong>
            Users have the following rights under relevant privacy laws:
          </strong>
          <ol className="list-items-data">
            <li>
              <strong> Right to be Informed: </strong>
              <span>
                Users have the right to know how their data is collected,
                processed, and used.
              </span>
            </li>

            <li>
              <strong> Right of Access: </strong>
              <span> Users can request access to their personal data. </span>
            </li>

            <li>
              <strong> Right to Rectification: </strong>
              <span>
                Users can request corrections to inaccurate or incomplete data
              </span>
            </li>

            <li>
              <strong> Right to Erasure: </strong>
              <span>
                Users can request the deletion of their personal data under
                certain circumstances.
              </span>
            </li>

            <li>
              <strong> Right to Restrict Processing: </strong>
              <span>
                Users can request the restriction of processing their data under
                certain conditions.
              </span>
            </li>

            <li>
              <strong> Right to Data Portability: </strong>
              <span>
                Users can request the transfer of their data to another service
                provider.
              </span>
            </li>

            <li>
              <strong> Right to Object </strong>
              <span>
                Users can object to the processing of their data in certain
                situations.
              </span>
            </li>
          </ol>
        </div>
        <div>
          <strong> Data Retention </strong>

          <p>
            User data is retained for as long as necessary to fulfill the
            purposes outlined in this Privacy Policy unless a longer retention
            period is required by law or regulation. Users will be notified if
            data is kept beyond this period.
          </p>

          <strong>Sharing Personal Data </strong>

          <ul className="list-items-data">
            <li>
              <strong> - Employees: </strong>
              <span>
                Data may be disclosed to members of our organization for the
                stated purposes.
              </span>
            </li>

            <li>
              <strong> - Third Parties: </strong>
              <span>
                We may share data with trusted third parties for
                business-building purposes, such as Google Analytics, Apple
                Analytics, and AWS.
              </span>
            </li>

            <li>
              <strong> - Google Analytics </strong>
              <span> For analyzing user interactions on our platform. </span>
            </li>

            <li>
              <strong>- Apple Analytics </strong>
              <span>
                For gathering insights into user engagement on iOS devices.
              </span>
            </li>
            <li>
              <strong>- AWS: </strong>
              <span> For secure storage and processing of user data. </span>
            </li>
          </ul>
        </div>

        <div>
          <strong> Other Disclosures </strong>
          <p>
            We will not sell or share data except in specific cases outlined in
            the Privacy Policy
          </p>

          <strong>Data Security Measures </strong>

          <strong>We implement comprehensive security measures:</strong>
          <ol className="list-items-data">
            <li>
              <strong>Encryption: </strong>
              <span>
                Strong browser and mobile encryption to protect user data during
                transmission.
              </span>
            </li>

            <li>
              <strong> Secure Facilities: </strong>
              <span>
                Data is stored in secure facilities, ensuring physical security
              </span>
            </li>

            <li>
              <strong> Limited Access: </strong>
              <span>
                Access to data is restricted to select employees who are bound
                by strict confidentiality agreements.
              </span>
            </li>

            <li>
              <strong>Regular Security Audits: </strong>
              <span>
                Conducting regular audits to ensure the effectiveness of our
                security measures.
              </span>
            </li>

            <li>
              <strong>Data Backups: </strong>
              <span> Regularly backing up user data to prevent data loss </span>
            </li>
          </ol>
        </div>
        <div>
          <strong> Automated Decision-Making and Profiling </strong>
          <p>
            We use automated decision-making for specific functions. Users have
            the right to object.
          </p>
          <strong>Children's Privacy </strong>
          <p>
            We do not knowingly collect or use personal data from children under
            16 years of age
          </p>
          <strong>User Control </strong>
          <strong> Users can exercise control over their data: </strong>
          <ol className="list-items-data">
            <li>
              <strong>Profile Deletion: </strong>
              <span>
                Users can delete their profile at any time by contacting
                welcome@angel-earth.com.
              </span>
            </li>
          </ol>
          <strong> Do Not Track Notice </strong>
          <p>We respond to browser-initiated DNT signals.</p>
          <strong> Opt-Out of Data Collection </strong>
          <p>
            We do not sell or distribute personal data. Users can opt-out by
            deleting their profiles.
          </p>
          <strong>Cookie Policy </strong>
          <p>We do not use cookies on our services.</p>
          <strong> Modifications </strong>
          <p>
            This Privacy Policy may be amended. Users are encouraged to
            periodically review the policy.
          </p>
          <strong>Complaints </strong>
          <p>
            Users with complaints can contact our privacy officer at
            welcome@angel-earth.com. If unsatisfied, they can contact the
            relevant supervisory authority.
          </p>
          <strong> Contact Information </strong>
          <p>
            <strong>Privacy Officer:</strong> Ravi Narayan
          </p>
          <p>
            <strong>Email:</strong>
            <a href="mailto:welcome@angel-earth.com">welcome@angel-earth.com</a>
          </p>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
