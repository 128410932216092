import React from "react";

import "./DtransferReadmore.css";
import GreenNature from "./Images/Green.png";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import Windimg from "./Images/wind-energy-image-definition 2.png";
import Joinsub from "./Images/Join-sub.png";
import Woman from "./Images/Woman.png";
import Canadapledge from "./Images/COP26 3.png";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import ShareButton from "../../TwitterShare/TwitterShare";
function DtransferReadmore() {
  return (
    <>
      <div
        className="Read-M-container"
        style={{ paddingTop: "2rem", position: "relative" }}
      >
        <div className="nav-tab">
          <div>
            <p>April 11, 2023</p>
          </div>

          <div>
            <ShareButton className="share-btn" />
          </div>
        </div>
        <div className="Dtrans-ReadM-Container">
          <div>
            <div className="headwithicon">
              <FaQuoteLeft
                style={{ color: "FAC14C", fontSize: "1rem" }}
                className="respo-quotes"
              />

              <p className="ReadM-Heading">
                A Sustainable and Innovative Solution.
              </p>
              <FaQuoteRight
                style={{ color: "FAC14C", fontSize: "1rem" }}
                className="respo-quotes"
              />
            </div>
            <div className="ReadM-p-div">
              <p id="read-m-PARA">
                DTransfer presents an intelligent and sustainable alternative.
                By adopting DTransfer, your corporation can eliminate the need
                for traditional paper receipts. Customers can simply scan a QR
                code on their smartphones, instantly accessing transaction
                records without the burden of accumulating paper waste. This
                small adoption can have a significant impact on your
                corporation's eco-footprint and the world at large.
              </p>
            </div>
            <div className="Green-img-div">
              <img src={GreenNature} alt="Green-Nature" />
            </div>
          </div>

          <div className="li-div1">
            <div className="li-1">
              <p className="read-mNum">01.</p>
            </div>
            <div className="info">
              <h2 className="li-1-heading">
                Electric and Autonomous Vehicles Integration: Pioneering Green
              </h2>
              <p className="li-1-para">
                The integration of electric and autonomous vehicles into the
                domain of dTransfer (Digital Transfers) presents a sustainable
                and innovative solution that not only enhances efficiency but
                also reduces environmental impact. Electric vehicles (EVs)
                significantly reduce carbon emissions, leading to cleaner air
                and reduced noise pollution. By incorporating autonomous
                technology, these vehicles can optimize routes, reduce idle
                time, and minimize traffic congestion, ultimately resulting in
                lower energy consumption. This dual approach promotes a greener
                and more efficient transportation system, aligning with the
                global shift toward sustainable practices.
              </p>
            </div>
          </div>

          <div className="li-div2">
            <div className="li-1">
              <p className="read-mNum">02.</p>
            </div>
            <div className="info">
              <h2 className="li-1-heading">
                Blockchain and Smart Contracts: Securing Transparent
                Transactions
              </h2>
              <p className="li-1-para">
                Blockchain technology coupled with smart contracts offers a
                transformative solution for enhancing transparency, security,
                and accountability in dTransfers. Blockchain ensures
                tamper-proof records, preventing fraud and ensuring the
                integrity of transactions. Smart contracts automate and
                self-execute terms and conditions, streamlining processes and
                reducing administrative overhead. This approach not only
                improves the reliability of dTransfers but also enables
                real-time auditing and data verification, contributing to a more
                sustainable and trustworthy digital ecosystem.
              </p>
            </div>
          </div>
          <div className="wind-img">
            <img src={Windimg} alt="WindIMG" className="wind-image-main" />
          </div>

          <div className="li-div2">
            <div className="li-1">
              <p className="read-mNum">03.</p>
            </div>
            <div className="info">
              <h2 className="li-1-heading">
                Renewable Energy-Powered Charging Infrastructure: Fueling a
                Green Future
              </h2>
              <p className="li-1-para">
                To address the energy demands of electric dTransfer vehicles,
                the implementation of renewable energy-powered charging
                infrastructure is crucial. Solar panels and wind turbines can
                generate clean energy to recharge electric vehicles, reducing
                dependence on fossil fuels and decreasing the carbon footprint
                of the transportation sector. This sustainable approach not only
                supports the electrification of dTransfers but also contributes
                to the wider adoption of renewable energy sources, fostering a
                more sustainable and resilient urban environment.
              </p>
            </div>
          </div>
        </div>
        <div className="card-flex-parent">
          <p className="Article">More Articles Like this</p>

          <div className="Card-flex">
            <div className="Read-M-card">
              <div className="Article-img">
                <img src={Joinsub} alt="Join-sub" className="Article-img" />
              </div>
              <p className="card-heading">
                Join the Sustainability Revolution.
              </p>
              <p className="card-Para">
                By embracing DTransfer, your corporation can lead the way
                towards a more sustainable future. Reduce paper waste, conserve
                resources, and minimize your carbon footprint . . .
              </p>
              <div className="card-btn-div">
                <button className="card-btn">READ MORE</button>
              </div>
              <div className="card-btm-data">
                <p className="card-brand">By Angel-Earth Corp</p>
                <p className="card-date">April 11, 2023</p>
              </div>
            </div>
            <div className="Read-M-card middle-card">
              <div className="Article-img">
                <img src={Woman} alt="Woman-img" className="Article-img" />
              </div>
              <p className="card-heading">Environmental Defence Study.</p>
              <p className="card-Para">
                Environmental Defence conducted a study on BPA and BPS in
                receipts, highlighting the detrimental effects of these
                chemicals on both human health and the environment . . .
              </p>
              <div className="card-btn-div">
                <button className="card-btn-middle card-btn">READ MORE</button>
              </div>
              <div className="card-btm-data-middle">
                <p className="card-brand card-brand-middle">
                  By Angel-Earth Corp
                </p>
                <p className="card-date card-brand-middle1">April 11, 2023</p>
              </div>
            </div>
            <div className="Read-M-card">
              <div className="Article-img">
                <img
                  src={Canadapledge}
                  alt="Canada-pledge"
                  className="Article-img"
                />
              </div>
              <p className="card-heading">Canada's Pledge at COP26</p>
              <p className="card-Para">
                Canada, along with more than 100 other countries, made a
                significant commitment at COP26 - the United Nations Climate
                Change Conference. The pledge aims to end deforestation and its
                devastating consequences by 2030. By adopting DTransfer . . .
              </p>
              <div className="card-btn-div">
                <button className="card-btn">READ MORE</button>
              </div>
              <div className="card-btm-data">
                <p className="card-brand">By Angel-Earth Corp</p>
                <p className="card-date">April 11, 2023</p>
              </div>
            </div>
          </div>
        </div>

        <div className="circle-buttons">
          <AiOutlineArrowLeft />
          <AiOutlineArrowRight />
        </div>
      </div>
    </>
  );
}

export default DtransferReadmore;
