import React from "react";
import "./ServiceComp.css";
import { useNavigate } from "react-router-dom";
function PushRadius() {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      navigate("/why-angel-app");
    }, 100);
  };
  return (
    <>
      <div className="Dtransfer-body">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-headdiv">
            <p className="headData">PUSH RADIUS</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="Dtransfer">
              {/* <div>
                <span className="verticle-line"></span>
              </div> */}
              <div className="DtransTxt">
                <p>
                  <strong>
                    Maximizing Promotional Impact with Push Radius!
                  </strong>
                  <br />
                  Welcome to Angel-App's Push Radius, a dynamic feature
                  synergizing seamlessly with Coupons, Flyers, and 1 Day
                  Campaigns to optimize promotional impact for businesses and
                  enhance the shopping experience for consumers. By harnessing
                  the power of geographic precision, businesses can deliver
                  targeted messages to nearby customers, driving engagement and
                  boosting sales.
                </p>
              </div>
            </div>

            <div className="Dtransfer-btn-container">
              <button
                className="D-transform-btn"
                onClick={handleButtonClick}
                style={{ cursor: "pointer" }}
              >
                Why Angel-App
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PushRadius;
