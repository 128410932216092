import React from "react";
import { useNavigate } from "react-router-dom";

import "./Dtransfer3.css";
const Dtransfer3 = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setTimeout(() => {
      navigate("/contact");
    }, 0);
  };

  return (
    <div style={styles.container} className="Dtransthree-container">
      <div
        style={{
          height: 34,
          width: 110,
          backgroundColor: "#000000",
          color: "#B8B8B8",
          fontSize: "1rem",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          fontWeight: "600",
          transform: "rotate(-90deg)",
          marginLeft: -37,
        }}
        id="contact-tag"
      >
        CONTACT
      </div>

      <div style={styles.Middlediv} className="GetIntouchcontainer">
        <p style={styles.LargeText} id="dtransfer-head">
          Get In Touch
        </p>
        <p style={styles.NormalText} className="dtrans-p">
          Have questions or need assistance? Reach out to our friendly support
          team.
        </p>
        {/* <div style={styles.ReadMoreBtn} className="getInTouchReadMoreBtn">
          <p
            style={styles.readmoretxt}
            onClick={handleContactClick}
            id="contact-btn-dtrans"
          >
            Contact Us
          </p>
        </div> */}

        <button
          style={{
            backgroundColor: "transparent",
            color: "#ffffffde",
            border: "1px solid #ffffffde",
            padding: "6px",
            borderRadius: "20px",
            fontWeight: 500,
            paddingLeft: "3%",
            paddingRight: "3%",
            fontSize: "13px",
            paddingTop: "1%",
            paddingBottom: "1%",
            marginTop: "10px",
            cursor: "pointer",
          }}
          onClick={handleContactClick}
        >
          Contact Us
        </button>
      </div>
      <Rectangles />
    </div>
  );
};
const Rectangles = () => {
  return (
    <div
      style={{
        display: "flex",
        width: "20%",
        height: "40vh",
      }}
      id="d-trans-box"
    >
      <div
        style={{
          width: "50%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={styles.boxes}></div>
      </div>
      <div
        style={{
          width: "50%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div style={styles.boxes}></div>
        <div style={styles.boxes}></div>
      </div>
    </div>
  );
};
const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "30vh",
    position: "relative",
    paddingTop: "2rem",
  },
  LargeText: {
    fontSize: "2.5em",
    color: "#ffffffde",
    margin: 4,
  },
  Middlediv: {
    width: "55%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  NormalText: {
    fontSize: "1em",
    color: "white",
    opacity: 0.6,
    margin: 0,
    width: "80%",
    letterSpacing: 1,
    wordSpacing: 2,
  },
  readmoretxt: {
    color: "#D7D7D7",
    fontSize: "0.8em",
    fontFamily: "Arial",
    margin: 0,
    fontWeight: "bold",
  },
  boxes: {
    width: "100%",
    height: "50%",
    background: "linear-gradient(177deg, rgb(111 107 107), rgba(0, 0, 0, 0))",
  },
};

export default Dtransfer3;
