import React, { useState } from "react";
import "./Accordion.css";
const AccorGrowPushRad = () => {
  const items = [
    {
      title: "Efficient Resource Allocation:",
      Number: "01",
      content:
        "Contribute to efficient resource allocation by focusing on specific geographic areas where your target audience is concentrated. Push Radius allows businesses to maximize the impact of each campaign while optimizing marketing budgets, ensuring a higher return on investment."
    },
    {
      title: "Enhanced Engagement:",
      Number: "02",

      content:
        "Facilitate enhanced consumer engagement through targeted exposure with Push Radius. By delivering timely and relevant promotional messages to nearby customers, businesses can increase interaction and foster deeper connections with their audience, ultimately driving loyalty and repeat business.",
    },
    {
      title: "Localized Marketing Mastery:",
      Number: "03",

      content:
        "Master localized marketing strategies by tailoring exposure radius to specific neighborhoods or citywide events. Push Radius enables businesses to adopt a personalized approach to promotions, ensuring that each message resonates with the unique preferences and behaviors of the target audience."
    },
    {
        title: "Flexible Business Strategies:",
        Number: "04",
  
        content:
          "Empower businesses with flexible strategies by adapting exposure radii based on campaign objectives and target demographics. Experimenting with different radius options allows businesses to fine-tune their promotional efforts and achieve diverse goals, from increasing foot traffic to driving online sales."
      },
      {
        title: "Collaborate for Targeted Success:",
        Number: "05",
  
        content:
          "Engage with Angel-App to harness the full potential of Push Radius with Coupons, Flyers, and 1 Day Campaigns. By implementing precise and effective promotional strategies, businesses can enhance brand visibility, drive customer engagement, and ultimately achieve targeted success. Partnering with Angel-App ensures a strategic approach to promotional endeavors, leveraging advanced features to maximize impact and ROI."
      },
   
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderItems = items.map((item, index) => {
    const isActive = index === activeIndex;
    const icon = isActive ? "-" : "+";

    return (
      <div key={index} className={`accordion-item ${isActive ? "active" : ""}`}>
        <div className="accordion-title" onClick={() => onTitleClick(index)}>
          <span>
            {" "}
            <span className="acco-num">{item.Number}</span> {item.title}
          </span>

          <span className="accordion-icon">{icon}</span>
        </div>
        {isActive && <div className="accordion-content">{item.content}</div>}
      </div>
    );
  });

  return <div className="accordion">{renderItems}</div>;
};

export default AccorGrowPushRad;
