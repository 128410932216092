import React, { useState } from "react";
import "./Accordion.css";
const AccorFeatPushNotif = () => {
  const items = [
    {
      title: "Geographic Precision:",
      Number: "01",
      content:
        "With Angel-App's Radius-Based Exposure feature, businesses can harness the power of geographic precision like never before. Choose exposure radii ranging from 10km to an entire city, ensuring your promotions reach the right audience in the right location.",
    },
    {
      title: "Localized Marketing Impact:",
      Number: "02",

      content:
        "Customize your marketing impact with localized strategies tailored to specific regions or neighborhoods. By understanding the unique preferences and behaviors of local customers, businesses can create promotions that resonate more deeply and drive higher engagement",
    },
    {
      title: "Tailored Audience Engagement:",
      Number: "03",

      content:
        "Tailor audience engagement by strategically selecting radius choices that align with your target demographic's preferences. By delivering personalized shopping experiences based on location, businesses can increase customer satisfaction and loyalty.",
    },
    {
      title: "Strategic Promotion Reminders:",
      Number: "04",
      content:
        "Stay top of mind with strategic promotion reminders sent directly to customers' devices. By reminding customers about ongoing promotions at the right time and place, businesses can maximize conversion rates and drive sales.",
    },
    {
      title: "Smart Engagement Strategies:",
      Number: "05",

      content:
        "Implement smart engagement strategies backed by data-driven insights. Analyze customer behavior and preferences to optimize the timing, frequency, and content of your notifications, ensuring maximum impact and effectiveness."
    },
    {
      title: "Responsive Customer Interaction:",
      Number: "06",

      content:
        "Foster responsive customer interaction with instant notifications that prompt immediate action. Whether it's informing customers about new arrivals, limited-time offers, or exclusive discounts, Angel-App's push notifications enable businesses to engage with customers dynamically and drive meaningful interactions."
    },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderItems = items.map((item, index) => {
    const isActive = index === activeIndex;
    const icon = isActive ? "-" : "+";

    return (
      <div key={index} className={`accordion-item ${isActive ? "active" : ""}`}>
        <div className="accordion-title" onClick={() => onTitleClick(index)}>
          <span>
            {" "}
            <span className="acco-num">{item.Number}</span> {item.title}
          </span>

          <span className="accordion-icon">{icon}</span>
        </div>
        {isActive && <div className="accordion-content">{item.content}</div>}
      </div>
    );
  });

  return <div className="accordion">{renderItems}</div>;
};

export default AccorFeatPushNotif;
