import React from "react";
import { useNavigate, Link } from "react-router-dom";
import PlayStore from "../../../assets/PlayStore.png";
import AppleStore from "../../../assets/download-on-the-app-store.svg";
import BrandLogo from "./Images/logo.png";
import "./Footer.css";
import MarqueeComp from "../../Marquee/Marquee";
const Footer = () => {
  const navigate = useNavigate();
  const handleLogoClick = () => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      navigate("/");
    }, 100);
  };

  const Navigationdata = [
    { name: "Home", id: 1, to: "/" },
    { name: "About US", id: 2, to: "/about" },
    { name: "Products", id: 3, to: "/services/dtransfer" },
    { name: "Pricing", id: 4, to: "/pricing" },
    { name: "Contact", id: 5, to: "/contact" },
    { name: "Why dTransfer", id: 6, to: "/why-angel-app" },
  ];

  const Servicesdata = [
    { name: "One Day Campaign", id: 1, to: "/services/onedaycampaign" },
    { name: "Flyers", id: 2, to: "services/flyer" },
    { name: "Coupons", id: 3, to: "services/coupon" },
    { name: "Storage", id: 4, to: "/services/storage" },
    { name: "dTransfer", id: 5, to: "/services/dtransfer" },
  ];

  const CareersData = [
    { name: "Full Time", id: 1, to: "/contact" },
    { name: "Part Time", id: 2, to: "/contact" },
  ];

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "1.5rem",
        }}
        id="the-footer-container"
      >
        <img
          src={BrandLogo}
          alt="Angel-Earth"
          onClick={handleLogoClick}
          style={{ cursor: "pointer" }}
        />
      </div>

      <div
        className="Ftext-container"
        style={{ maxWidth: "100%", paddingBottom: "2rem" }}
      >
        <div>
          <MarqueeComp />
        </div>
      </div>

      <div
        style={{
          minHeight: "44vh",
          width: "100vw",
          backgroundColor: "black",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <div className="Footer-mid-part">
          <div style={{ width: "40%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p
                style={{
                  fontSize: "1.5em",
                  fontWeight: "bold",
                  color: "#ffffffde",
                }}
                id="download-txt-footer"
              >
                Download App
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                id="img-container-footer"
              >
                <a
                  href="https://play.google.com/store/apps/details?id=com.angel.earth.angel.app"
                  target="_blank"
                  style={{ cursor: "pointer" }}
                  rel="noopener noreferrer"
                >
                  <img
                    src={PlayStore}
                    width={140}
                    height={48}
                    alt="Play Store"
                    className="footer-playstore-img"
                  />
                </a>

                <a
                  href="https://apps.apple.com/us/app/angel-app/id6473820385"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={AppleStore}
                    width={155}
                    height={44}
                    alt="Apple Store"
                    className="footer-app-store-img"
                  />
                </a>
              </div>
            </div>
          </div>
          <div
            className="Footer-mid-right"
            style={{ cursor: "pointer", display: "flex" }}
          >
            <Navs Name={"NAVIGATION"} data={Navigationdata} />
            <Navs Name={"SERVICES"} data={Servicesdata} />
            <Navs Name={"CAREERS"} data={CareersData} />
          </div>
        </div>
        <div className="footer-Bottom-part">
          <div style={{ display: "flex" }}>
            <a
              style={styles.bottomLinks}
              href="https://twitter.com/AngelEarthCorp1"
              target="_blank"
              rel="noopener noreferrer"
              className="link-ones"
            >
              X
            </a>

            <a
              href="https://www.instagram.com/true.angel.app/?hl=en"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                ...styles.bottomLinks,
                borderRight: "solid 1px #ffffffde",
                borderLeft: "solid 1px #ffffffde",
                paddingLeft: 8,
                paddingRight: 8,
              }}
              className="link-ones"
            >
              Instagram
            </a>

            <a
              style={styles.bottomLinks}
              href="https://www.facebook.com/profile.php?id=100063787165466"
              target="_blank"
              rel="noopener noreferrer"
              className="link-ones"
            >
              Facebook
            </a>

            <a
              style={{
                ...styles.bottomLinks,
                borderLeft: "solid 1px #ffffffde",
                paddingLeft: 8,
                paddingRight: 8,
              }}
              href="https://www.tiktok.com/@trueangelapp"
              target="_blank"
              rel="noopener noreferrer"
              className="link-ones"
            >
              TikTok
            </a>
          </div>

          <div style={{ display: "flex" }} id="btm-links">
            <p
              style={styles.bottomLinks}
              className="ahref-linkone"
              rel="noopener noreferrer"
            >
              © 2024 Angel-Earth Corporation. All rights reserved
            </p>
            <Link
              to="/terms-and-conditions"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              rel="noopener noreferrer"
              className="anchor-form anchor-two ahref-footers"
              style={{
                ...styles.bottomLinks,
                borderRight: "solid 1px #ffffffde",
                borderLeft: "solid 1px #ffffffde",
                paddingLeft: 8,
                paddingRight: 8,
              }}
            >
              Term And Conditions
            </Link>

            <Link
              to="/privacy-policy"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              rel="noopener noreferrer"
              className="anchor-form anchor-two ahref-footers"
              style={styles.bottomLinks}
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const Navs = ({ data, Name }) => {
  const navigate = useNavigate();

  const handleClick = (to) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setTimeout(() => {
      navigate(to);
    }, 100);
  };
  return (
    <div
      style={{
        marginRight: "15px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
      id="footer-links-respo-div"
    >
      <p className="Footer-m-r-Large-text">{Name}</p>
      {data?.map((val) => (
        <span
          key={val.id}
          className="Footer-m-r-Small-text"
          style={styles.smallText}
          onClick={() => handleClick(val.to)}
        >
          {val?.name}
        </span>
      ))}
    </div>
  );
};

const styles = {
  Largetext: {
    fontSize: "1.2rem",
    color: "#ffffffde",
    fontFamily: "arial",
  },
  smallText: {
    fontSize: "0.8rem",
    color: "#717171",
    fontFamily: "Arial",
    letterSpacing: 1,
    display: "block",
    marginBottom: "5px",
    textDecoration: "none",
    cursor: "pointer",
  },
  bottomLinks: {
    color: "#ffffffde",
    fontSize: "0.9rem",
    margin: 8,
    textDecoration: "none",
    cursor: "pointer",
  },
  VerticleLIne: {
    borderRight: "solid 1px #ffffffde",
    borderLeft: "solid 1px #ffffffde",
    paddingLeft: 8,
    paddingRight: 8,
  },
  FooterIMGContainer: {
    display: "flex",
    gap: "10px",
  },
};

export default Footer;
