import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Dataofslider from "../Company/Dataofautoslider/Dataofslider";
import "./AutoSlider.css";
function useWindowSize() {
  const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
  useEffect(() => {
    const handleResize = () => setSize([window.innerHeight, window.innerWidth]);
    window.addEventListener("resize", handleResize);
  }, []);
  return size;
}
const array = Dataofslider.map((x) => {
  return x.image;
});
function App() {
  useEffect(() => {
    const dots = document.querySelectorAll(".slick-dots li button");
    dots.forEach((dot) => (dot.innerHTML = ""));
  }, []);
  const [imageIndex, setImageIndex] = useState(0);
  const [height, width] = useWindowSize();
  const settings = {
    className: "center",
    infinite: true,
    lazyLoad: true,
    speed: 300,
    slidesToShow: 3,
    centerMode: true,
    centerPadding: "10px",
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    beforeChange: (current, next) => {
      setImageIndex(next);
    },
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="autosliderparent"
      >
        <div className="Apps">
          <Slider {...settings}>
            {array.map((img, idx) => (
              <div
                key={idx}
                className={
                  idx === imageIndex ? "slides activeSlides" : "slides"
                }
              >
                <img src={img} alt={img} />
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </>
  );
}

export default App;
