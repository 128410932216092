import React from "react";
import { Helmet } from "react-helmet";
import ContactUs from "../../Components/Contact/ContactUs";

function ContactUsPage() {
  const pageTitle = "Contact Us - Angel Earth";
  const pageDescription =
    "Get in touch with Angel Earth for inquiries, support, or collaborations. Contact us to learn more about our services and solutions.";

  const brandLogoUrl = `${process.env.PUBLIC_URL}/A-ELogo.png`;
  const cdnImageUrl =
    "https://images.pexels.com/photos/7869111/pexels-photo-7869111.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={brandLogoUrl} />
        <meta property="og:url" content="https://angel-earth.com/contact" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={cdnImageUrl} />
      </Helmet>
      <ContactUs />
    </>
  );
}

export default ContactUsPage;
