import React from "react";
import "./Why.css";
function Why() {
  return (
    <div style={{ position: "relative" }}>
      <div id="why-angel-app">
        <div className="Angel-text-container-why">
          <p className="Angel-Moving-why">ANGEL-EARTH</p>
        </div>
        <p className="why-indication">WHY US</p>
        <div className="headings">
          <h2 style={{ marginBottom: 0 }} className="why-main-head">
            Why Should You Choose{" "}
          </h2>
          <br />
          <h2 style={{ marginTop: -30 }} className="why-main-head">
            Angel-Earth Products?
          </h2>
        </div>

        <div id="why" style={{ position: "relative" }} className="why-holder">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8rem",
                }}
                className="why-holderdiv"
              >
                <div>
                  <div className="container-headwhy">
                    <p
                      style={{
                        color: "#7E7B7B",
                        fontWeight: "bold",
                        fontSize: "19px",
                      }}
                    >
                      01
                    </p>
                    <h6 className="heading-why" id="streamlined-ptag">
                      Efficiency
                    </h6>
                  </div>
                  {/* <div className="why-respo-heading">
                    <p
                      style={{ color: "#7E7B7B", fontWeight: "bold" }}
                      className="why-nums"
                    >
                      01
                    </p>

                    <p className="heading-why" id="streamlined-ptag">
                      Efficiency
                    </p>
                  </div> */}

                  <p className="whydata why-para">
                    Angel-App simplifies daily life with our intuitive SaaS
                    solutions. Our products are designed with an emphasis on
                    providing value and enhancing productivity and efficiency
                    for both businesses and consumers. Businesses can promote
                    products and events with proximity-based marketing tools to
                    interact directly with consumers through ads and promotions;
                    which means businesses reach their desired consumers and
                    consumers find promotions that align with their needs and
                    desires. Say goodbye to physical documents and their many
                    shortcomings. No more damaged, wet or lost documents and
                    receipts! Everything is instead stored digitally in our
                    secure cloud servers.
                  </p>
                </div>

                {/* <div className="img-container-flexible">
                  <img
                    src={HappyTeam}
                    className="why-images"
                    alt="why-choose-AngelEarth"
                  />
                </div> */}
              </div>
            </div>
          </div>
          <div className="imgcontainerwhy">
            <div className="containimg">
              <img
                src="https://images.pexels.com/photos/3184339/pexels-photo-3184339.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                srcset=""
              />
            </div>
            <div className="containimg hideonsmphone">
              <img
                src="https://images.pexels.com/photos/3184357/pexels-photo-3184357.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                srcset=""
              />{" "}
            </div>
            <div className="containimg hideonphone">
              <img
                src="https://images.pexels.com/photos/3760069/pexels-photo-3760069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                srcset=""
              />
            </div>
            <div className="containimg hideonphone showtab">
              <img
                src="https://images.pexels.com/photos/5324947/pexels-photo-5324947.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                srcset=""
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "8.25rem",
              alignItems: "center",
            }}
            className="col-revs-why why-holderdiv"
          >
            {/* <div className="securityimg-container">
              <img src={TypingIMG} className="Security-img" />
            </div> */}

            <div>
              <div className="container-headwhy">
                <p
                  style={{
                    color: "#7E7B7B",
                    fontWeight: "bold",
                    fontSize: "19px",
                  }}
                >
                  02
                </p>
                <h6 className="heading-why" id="streamlined-ptag">
                  Security And Simplicity
                </h6>
              </div>
              {/* <div className="why-respo-heading">
                <p
                  style={{ color: "#7E7B7B", fontWeight: "bold" }}
                  className="why-nums"
                >
                  02
                </p>
                <p className="heading-why" style={{ margin: 0 }}>
                  Security And Simplicity
                </p>
              </div> */}
              <p className="whydata why-para">
                Rest easy knowing that sensitive data is protected privately in
                our cloud servers with our state-of-the-art security measures
                and robust encryption. Store, easily sort, and manage data in
                our innovative cloud networking systems that provide useful
                analytics for both businesses and consumers. Our app is free to
                download for consumers and works on a subscription for
                businesses.
              </p>
            </div>
          </div>
          <div className="Angel-text-container">
            <p className="Angel-Moving-why">ANGEL-EARTH</p>
          </div>
          <div className="imgcontainerwhy">
            <div className="containimg">
              <img
                src="https://images.pexels.com/photos/4308102/pexels-photo-4308102.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                srcset=""
              />
            </div>
            <div className="containimg hideonsmphone">
              <img
                src="https://images.pexels.com/photos/3184345/pexels-photo-3184345.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                srcset=""
              />{" "}
            </div>
            <div className="containimg hideonphone">
              <img
                src="https://images.pexels.com/photos/7792743/pexels-photo-7792743.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                srcset=""
              />
            </div>
            <div className="containimg hideonphone showtab">
              <img
                src="https://images.pexels.com/photos/7869111/pexels-photo-7869111.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                srcset=""
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "0rem",
              marginTop: "1rem",
            }}
            className="why-holderdiv"
          >
            <div className="texts-why-holder">
              <div className="container-headwhy">
                <p
                  style={{
                    color: "#7E7B7B",
                    fontWeight: "bold",
                    fontSize: "19px",
                  }}
                >
                  03
                </p>
                <h6 className="heading-why" id="streamlined-ptag">
                  Life Changing
                </h6>
              </div>
              {/* <div className="why-respo-heading">
                <p
                  style={{ color: "#7E7B7B", fontWeight: "bold" }}
                  className="why-nums"
                >
                  03
                </p>

                <p className="heading-why">Life Changing</p>
              </div> */}
              <p className="whydata why-para">
                Seamlessly integrate our SAAS Products with your business's
                existing tools and systems to maximize their impact. Consumers
                then simply download the app to interface with your business's
                already integrated systems which can facilitate the scanning and
                management of your promotions as well as transfer other
                documents such as secure receipts through our patented dTransfer
                to any customer smartphone. Our innovative retail system is
                convenient both for you and your customers and requires no extra
                equipment.
              </p>
            </div>
            <div className="img-why-holder">
              {/* <img
                // src={flexibleimage}
                src={flexibleimage}
                className="integration-img"
              /> */}
            </div>

            {/* <div className="Angel-text-container-why">
              <p className="Angel-Moving-why">ANGLE-EARTH</p>
            </div> */}
          </div>
          <div className="imgcontainerwhy">
            <div className="containimg">
              <img
                src="https://images.pexels.com/photos/8171195/pexels-photo-8171195.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                srcset=""
              />
            </div>
            <div className="containimg hideonsmphone">
              <img
                src="https://images.pexels.com/photos/3184611/pexels-photo-3184611.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                srcset=""
              />{" "}
            </div>
            <div className="containimg hideonphone">
              <img
                src="https://images.pexels.com/photos/3756679/pexels-photo-3756679.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                srcset=""
              />
            </div>
            <div className="containimg hideonphone showtab">
              <img
                src="https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                alt=""
                srcset=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Why;
