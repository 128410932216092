import React from "react";
import "./ServiceComp.css";
import { useNavigate } from "react-router-dom";
function OneDayCamp() {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setTimeout(() => {
      navigate("/why-angel-app");
    }, 100);
  };
  return (
    <>
      <div className="Dtransfer-body">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-headdiv">
            <p className="headData">ONE DAY CAMPAIGN</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="Dtransfer">
              <div className="DtransTxt">
                <p>
                  Welcome to Angel-App's 1 Day Campaign—a thrilling journey into
                  daily savings and exciting deals for both consumers and
                  businesses. Immerse potential customers in short-term
                  promotional campaigns crafted to bring exclusive offers,
                  discounts, and irresistible deals. With Angel-App's innovative
                  platform, businesses can create dynamic promotions that
                  generate buzz, foster excitement, and drive customer
                  engagement, making each day an opportunity for savings and
                  success.
                </p>
              </div>
            </div>

            <div className="Dtransfer-btn-container">
              <button
                className="D-transform-btn"
                onClick={handleButtonClick}
                style={{ cursor: "pointer" }}
              >
                Why Angel-App
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OneDayCamp;
