import React from "react";
import "./Privacy.css";
import { Link } from "react-router-dom";
function Privacy() {
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scrolls to the top of the page smoothly
  };

  return (
    <>
      <div className="privacy-container">
        <p className="privacy">PRIVACY</p>
        <p className="privacy-heading">Your Privacy is our Priority!</p>

        <div className="text-container-privacy">
          <p className="text-privacy-para">
            Privacy, in short, is the right to be free from interference or
            intrusion — the ability to be left alone without being watched.
            Information refers to the right to have control over how your
            personal information and data are collected, stored, and used.
            Privacy policies are in place to protect sensitive information and
            safeguard your identity. Privacy is often about using sensitive
            information responsibly.
          </p>
        </div>

        <div className="text-container-privacy">
          <p className="text-privacy-para">
            Angel-Earth is required to be transparent about what forms of data
            they intend to collect, the purpose of the data collection, and
            where and with whom it is to be shared. As the user, you have to
            accept these terms and conditions; therefore, you have the right to
            control your shared information.
          </p>
        </div>
        <div style={{marginTop:"18px"}}>
          {/* <a href="https://www.angel-earth.com/#/register">
            <button className="D-transform-Info-btnn">Read More</button>
          </a> */}

          <Link to="/privacy-policy" rel="noopener noreferrer" >
            <button className="D-transform-Info-btnn" onClick={handleScrollToTop}>Read More</button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Privacy;
