import React from "react";
import "./ServiceComp.css";
import { useNavigate } from "react-router-dom";
function PushNotifications() {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      navigate("/why-angel-app");
    }, 100);
  };
  return (
    <>
      <div className="Dtransfer-body">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="service-headdiv">
            <p className="headData">PUSH NOTIFICATIONS</p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="Dtransfer">
              {/* <div>
                <span className="verticle-line"></span>
              </div> */}
              <div className="DtransTxt">
                <p>
                  <strong>
                    Push Notifications: Amplifying Business Reach with
                    Geo-Targeting!
                  </strong>
                  <br />
                  Angel-App introduces Radius-Based Exposure, allowing
                  businesses to fine-tune promotions with geographic precision.
                  Now, reach your target audience like never before by sending
                  targeted notifications based on their location, ensuring your
                  messages are timely, relevant, and highly effective.
                </p>
              </div>
            </div>

            <div className="Dtransfer-btn-container">
              <button
                className="D-transform-btn"
                onClick={handleButtonClick}
                style={{ cursor: "pointer" }}
              >
                Why Angel-App
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PushNotifications;
