import React from "react";
import imageone from "./Images/Employee-morale-is-boosted 1.png";
import imagetwo from "./Images/What-does-work-culture-mean 1.png";
import "./NavigateCulture.css";
function NavigateCulture() {
  return (
    <>
      <div style={{}}>
        <div
          style={{ display: "flex", justifyContent: "flex-end" }}
          className="flexonediv"
        >
          <div className="culture-container-one  culture-respo-one">
            <div className="cultureOne-div">
              <div className="cultureOne-div-childone">
                <div className="culture-head-div">
                  <p className="num">01</p>
                  <p className="culture-headingone Headings">
                    People Focused
                  </p>
                </div>

                <p className="culture-para">
                At Angel-Earth, our dedication to putting people first permeates every aspect of our organization. We believe in harnessing the diverse talents and perspectives of our team, which mirrors the richness and strength of our wider community. Through our unwavering commitment to excellence, we ensure that every member of our team embodies the high standards and values that define Angel Earth.
                </p>
              </div>
            </div>

            <div className="culture-one-div-img">
              <img src={imageone} alt="angel-EarthCulture" />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "1.5rem",
          }}
        >
          <div className="culture-container-one culture-respo-two">
            <div className="culture-two-div-img">
              <img src={imagetwo} alt="Angel-Earth-culture" />
            </div>

            <div className="cultural-txt-divtwo">
              <div className="cultureOne-div-childtwo">
                <div className="culture-head-div heading-divs">
                  <p className="num num-two">02</p>
                  <p className="culture-heading Headings">Respect</p>
                </div>
                <div className="culture-para">
                  <p>
                  At the core of our ethos lies a profound respect for every individual, which serves as the foundation of our operations. Our horizontal management structure fosters an environment where every voice is not only heard but valued, allowing for a rich tapestry of perspectives to shape our products and content. This inclusive approach ensures that the collective wisdom and creativity of our diverse team are seamlessly woven into everything we produce, resulting in offerings that resonate deeply with our audience.
                  </p>
                  <p>
                    {/* All they need to do is offer consistent praise and
                    recognition. Doing this creates excitement among teams and
                    gives them enough flexibility for a more successful life at
                    work. */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavigateCulture;
