import React from "react";
import { Helmet } from "react-helmet";
import AboutUs from "../../Components/AboutUs/AboutUs";

function AboutUsPage() {
  const brandLogoUrl = `${process.env.PUBLIC_URL}/A-ELogo.png`;
  const cdnImageUrl =
    "https://images.pexels.com/photos/7869111/pexels-photo-7869111.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";

  return (
    <>
      <Helmet>
        <title>About Us - Angel Earth</title>
        <meta
          name="description"
          content="Learn more about Angel Earth, our mission, vision, and the innovative digital solutions we provide to empower businesses."
        />
        <meta property="og:title" content="About Us - Angel Earth" />
        <meta
          property="og:description"
          content="Learn more about Angel Earth, our mission, vision, and the innovative digital solutions we provide to empower businesses."
        />
        <meta property="og:image" content={brandLogoUrl} />
        <meta property="og:url" content="https://angel-earth.com/about" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="About Us - Angel Earth" />
        <meta
          name="twitter:description"
          content="Learn more about Angel Earth, our mission, vision, and the innovative digital solutions we provide to empower businesses."
        />
        <meta name="twitter:image" content={cdnImageUrl} />
      </Helmet>
      <AboutUs />
    </>
  );
}

export default AboutUsPage;
