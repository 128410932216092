import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
const WhatsAppWidget = () => {
  const phoneNumber = "+17803772179";
  const message = "Hello! I need assistance.";
  const handleWhatsAppClick = () => {
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappURL, "_blank");
  };
  return (
    <>
      <div style={styles.ArrowContainer} onClick={handleWhatsAppClick}>
        <WhatsAppIcon style={{ color: "#fff", fontSize: "25px" }} />
      </div>
    </>
  );
};
const styles = {
  ArrowContainer: {
    position: "fixed",
    bottom: "29px",
    left: "14px",
    zIndex: "2000",
    background: "#389E47",
    height: "45px",
    width: "45px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
};
export default WhatsAppWidget;
