import React from "react";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
const ScrollToTopButton = () => {
  const ScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div onClick={ScrollToTop} style={styles.ArrowContainer}>
      <ArrowUpwardIcon />
    </div>
  );
};
const styles = {
  ArrowContainer: {
    position: "fixed",
    bottom: "29px",
    right: "14px",
    zIndex: "2000",
    background: "#389E47",
    height: "45px",
    width: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: "50%",
  },
};
export default ScrollToTopButton;
