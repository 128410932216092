import { React, useEffect, useRef } from "react";

import { Helmet } from "react-helmet";
import AccorFeatOneDay from "../../services/accordion/AccorFeatOneDay";
import AccorGrowOneDay from "../../services/accordion/AccorGrowOneDay";
import Information from "../../services/information/Information";
import Servicelists from "../../services/servicelists/Servicelists";
import "./NavigateComp.css";
import OneDayCampaign from "../../services/servicerender/ServiceComp/OneDayCamp";
import { useNavigate, useLocation } from "react-router-dom";
function NavigateOneDayCampaign() {
  const navigate = useNavigate();
  const location = useLocation();
  const oneDayRef = useRef(null);
  const scrollToOneDay = () => {
    if (location.pathname === "/services/onedaycampaign" && oneDayRef.current) {
      window.scrollTo({
        top: oneDayRef.current.offsetTop,
        behavior: "smooth",
      });
    } else {
      navigate("/services/onedaycampaign");
    }
  };
  useEffect(() => {
    if (location.pathname === "/services/onedaycampaign" && oneDayRef.current) {
      window.scrollTo({
        top: oneDayRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, [location.pathname]);

  const pageTitle =
    "One Day Campaign - Rapid promotional strategy for immediate impact";
  const pageDescription =
    "Discover the features and growth potential of One Day Campaigns. Leverage rapid promotional strategies to engage your audience and drive instant results.";
  const brandLogoUrl = `${process.env.PUBLIC_URL}/A-ELogo.png`;
  const cdnImageUrl =
    "https://images.pexels.com/photos/3184357/pexels-photo-3184357.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={brandLogoUrl} />
        <meta
          property="og:url"
          content="https://angel-earth.com/services/onedaycampaign"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={cdnImageUrl} />
      </Helmet>
      <Servicelists scrollToOneDay={scrollToOneDay} />
      <div ref={oneDayRef}>
        <OneDayCampaign />
      </div>
      <div className="Services-grid">
        <div>
          <p className="Accor-head">FEATURES</p>
        </div>
        <div>
          <AccorFeatOneDay />
        </div>
      </div>

      <div className="Services-grid">
        <div>
          <p className="Accor-head">
            HERE’S HOW WE
            <br /> HELP YOU GROW
          </p>
        </div>
        <div>
          <AccorGrowOneDay />
        </div>
      </div>

      <Information />
    </>
  );
}

export default NavigateOneDayCampaign;
