import React, { useState } from "react";
import "./Accordion.css";
const AccorGrowCoupon = () => {
  const items = [
    {
      title: "Cost-Effective Marketing:",
      Number: "01",
      content:
        "Maximize your budget with targeted campaigns, increasing ROI. By focusing resources on specific customer segments and tailoring promotions to their preferences, businesses can optimize their marketing spend and achieve greater returns on investment.",
    },
    {
      title: "Enhanced Customer Retention:",
      Number: "02",

      content:
        "Consistently deliver relevant offers to build customer loyalty. By offering personalized discounts and promotions through Angel-App's coupon platform, businesses can keep customers engaged and coming back for more, ultimately increasing retention rates and lifetime value.",
    },
    {
      title: "Data-Driven Decision Making:",
      Number: "03",

      content:
        "Leverage AI insights for informed decisions and refined marketing strategies. With access to detailed analytics and predictive modeling, businesses can make data-driven decisions that optimize their coupon promotions for maximum impact and effectiveness.",
    },
    {
        title: "Brand Visibility:",
        Number: "04",
  
        content:
          "Increase brand recognition by offering exclusive discounts. By providing customers with unique and compelling offers, businesses can increase brand visibility and awareness, attracting new customers and retaining existing ones.",
      },
      {
        title: "Collaborate for Targeted Success:",
        Number: "05",
  
        content:
          "Engage with Angel-App to harness the full potential of Coupons with Push Radius, Push notifications, Flyers, 1 Day Campaigns, and Storage. Implement precise and effective promotional strategies, enhancing brand visibility, and driving customer engagement. Partnering with Angel-App ensures a strategic approach to your promotional endeavors, leveraging advanced features and insights to achieve targeted success.",
      },
   
     
     
   
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderItems = items.map((item, index) => {
    const isActive = index === activeIndex;
    const icon = isActive ? "-" : "+";

    return (
      <div key={index} className={`accordion-item ${isActive ? "active" : ""}`}>
        <div className="accordion-title" onClick={() => onTitleClick(index)}>
          <span>
            {" "}
            <span className="acco-num">{item.Number}</span> {item.title}
          </span>

          <span className="accordion-icon">{icon}</span>
        </div>
        {isActive && <div className="accordion-content">{item.content}</div>}
      </div>
    );
  });

  return <div className="accordion">{renderItems}</div>;
};

export default AccorGrowCoupon;
