// CustomSlider.js

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import './CustomSlider.css'; // Import the CSS file with the provided styles
import "./NavigateStory.css"


import slideimgone from './Images/What-Does-Work1.jpg';
import slideimgtwo from './Images/male-customer-showing-mobile-phone-while-doing-contactless-payment-to-cashier-at-cafe-MASF10240.jpg';

const CustomSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <div className="custom-slider-container">
      <Slider {...settings}>
        <div>
          <img src={slideimgone} alt="Slide 1" className="slider-image" />
        </div>
        <div>
          <img src={slideimgtwo} alt="Slide 2" className="slider-image" />
        </div>  <div>
          <img src={slideimgtwo} alt="Slide 2" className="slider-image" />
        </div>
        {/* Add more slides as needed */}
      </Slider>
    </div>
  );
};

export default CustomSlider;
