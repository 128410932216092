import React from "react";
import { Helmet } from "react-helmet";
import Explore from "../../Components/Homepage/explore/Explore";
import Unlock from "../../Components/Homepage/unlock/Unlock";
import Why from "../../Components/Homepage/whyPage/WhyUs";
// import Testimonials from "../../Components/Homepage/Testimonials/Testimonials";
import Dtransfer3 from "../../Components/Dtransfer/Dtransfer3/Dtransfer3";
function Homepage() {
  const brandLogoUrl = `${process.env.PUBLIC_URL}/A-ELogo.png`;
  const cdnImageUrl =
    "https://images.pexels.com/photos/4308102/pexels-photo-4308102.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";

  return (
    <>
      <Helmet>
        <title>Home - Angel Earth</title>
        <meta
          name="description"
          content="Welcome to Angel Earth - Empowering businesses with innovative digital solutions."
        />
        <meta property="og:title" content="Home - Angel Earth" />
        <meta
          property="og:description"
          content="Welcome to Angel Earth - Empowering businesses with innovative digital solutions."
        />
        <meta property="og:image" content={brandLogoUrl} />
        <meta property="og:url" content="https://angel-earth.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Home - Angel Earth" />
        <meta
          name="twitter:description"
          content="Welcome to Angel Earth - Empowering businesses with innovative digital solutions."
        />
        <meta name="twitter:image" content={cdnImageUrl} />{" "}
      </Helmet>
      <Explore />
      <Unlock />
      <Why />

      <Dtransfer3 />
    </>
  );
}

export default Homepage;
