import React from "react";
import DoneIcongrey from "../../../assets/DoneIcongrey.png";
import Privacy from "../../Pricing/PricingCards/Privacy/Privacy";
import MoneyBack from "../../Pricing/moneyback/MoneyBack";
function FlyerPricing() {
  const redirectToLogin = (url) => {
    window.open(url, "_blank");
  };

  const handleDownload = () => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.angel.earth.angel.app",
        "_blank"
      );
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
      window.open(
        "https://apps.apple.com/us/app/angel-app/id6473820385",
        "_blank"
      );
    } else {
      window.open("https://dashboard.angel-earth.com/#/login", "_blank");
    }
  };

  const cardData = [
    {
      region: "7 Days",
      amount: "$ 75.00",
      // amount: "Free",
      features: [
        "Visually Appealing Content",
        "Targeted Promotions",
        "Data-Driven Flyer Strategies",
        "Geographic Customization",
        "Radius and Vicinity-Based Push Notifications",
        "Integration with other Angel-App products",
        "Includes Angel-AI",
        "Push Notification included",
        "7 Days = $75.00",
        "Push Radius $1.00/ Per Push"
        // "7 Days = Free",
        // "Push Radius = Free / Per Push",
      ],
    },

    {
      region: "14 Days",
      amount: "$ 125.00",
      // amount: "Free",
      features: [
        "Visually Appealing Content",
        "Targeted Promotions",
        "Data-Driven Flyer Strategies",
        "Geographic Customization",
        "Radius and Vicinity-Based Push Notifications",
        "Integration with other Angel-App products",
        "Includes Angel-AI",
        "Push Notification included",
        "14 Days = $125.00",
        "Push Radius $1.00/ Per Push"
        // "14 Days = Free",
        // "Push Radius = Free / Per Push",
      ],
    },

    {
      region: "21 Days",
      amount: "$ 175.00",
      // amount: "Free",
      features: [
        "Visually Appealing Content",
        "Targeted Promotions",
        "Data-Driven Flyer Strategies",
        "Geographic Customization",
        "Radius and Vicinity-Based Push Notifications",
        "Includes Angel-AI",
        "Integration with other Angel-App products",
        "Push Notification included",
        "21 Days = $175.00",
        "Push Radius $1.00/ Per Push"
        // "21 Days = Free",
        // "Push Radius = Free / Per Push",
      ],
    },
    {
      region: "30 Days",
      amount: "$ 225.00",
      // amount: "Free",
      features: [
        "Visually Appealing Content",
        "Targeted Promotions",
        "Data-Driven Flyer Strategies",
        "Geographic Customization",
        "Radius and Vicinity-Based Push Notifications",
        "Integration with other Angel-App products",
        "Includes Angel-AI",
        "Push Notification included",
        "30 Days = $225.00",
        "Push Radius $1.00/ Per Push"
        // "30 Days = Free",
        // "Push Radius Free / Per Push",
      ],
    },
  ];

  return (
    <>
      <div style={{ marginTop: "2rem" }}>
        <div style={styles.DtransferBtn}>
          <p>Flyer</p>
        </div>

        <p
          style={{
            fontSize: "2.4em",
            fontWeight: "bold",
            margin: 4,
            textAlign: "center",
          }}
          className="Respo-PricingHeading"
        >
          Choose a plan that fits <br /> you best.
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "2.5rem",
            flexWrap: "wrap",
          }}
          className="Respo-PricingCards"
        >
          {cardData.map((card, index) => (
            <div className="card-one-day card-dtrans" key={index}>
              <div className="top-items">
                <div></div>
              </div>
              <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="citywide theregion"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: card.region }}
                    ></span>
                  </div>

                  <div className="values pricing-values">
                    <h2 className="payamount pricing-amount Headings">
                      {card.amount}
                    </h2>
                    {/* <span className="spantxt">/ per Month</span> */}
                  </div>
                </div>
                <div style={{ marginTop: "2.6rem" }}>
                  <ul className="lists pricing-list">
                    {card.features.map((feature, index) => (
                      <div key={index} className="li-data">
                        <div
                          style={{
                            display: "flex",
                            gap: "13px",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={DoneIcongrey}
                            style={{
                              maxHeight: "18px",
                              maxWidth: "18px",
                              marginBottom: "3px",
                            }}
                            alt="done-icon-gray"
                          />
                          {/* <span className="featurepricing" style={{marginBottom:"3px",fontSize:"12px"}}>{feature}</span>
                           */}

                          <span className="featurepricing">
                            {feature.includes("Free") ? (
                              <b>{feature}</b>
                            ) : (
                              feature
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="btn-div pricingBTN-div">
                <button className="btn pricing-btn" onClick={handleDownload}>
                  Explore
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="why-angel-app-container">
        <h2 style={styles.heading}>Flyer</h2>

        <p className="text-para-dtrans">
          Welcome to Angel-App's vibrant digital flyer experience, where your
          business's latest promotions and highlights come to life. Produce
          interactive flyers that provide consumers with real-time updates,
          personalized recommendations, and user-friendly navigation for a
          seamless and exciting shopping experience. With Angel-App's innovative
          digital flyer platform, businesses can captivate their audience and
          drive engagement like never before, creating memorable shopping
          experiences that leave a lasting impression.
        </p>
      </div>
      <Privacy />
      <MoneyBack />
    </>
  );
}

const styles = {
  DtransferBtn: {
    width: "6.3em",
    height: "2.2em",
    borderRadius: "1.2em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.9em",
    color: "#00C7FF",
    border: "#00C7FF solid 1px",
    margin: "auto",
  },

  heading: {
    textAlign: "center",
  },
};

export default FlyerPricing;
