import React, { useState } from "react";
import "./Accordion.css";
const AccorFeatOneDay = () => {
  const items = [
    {
      title: " Dynamic Promotional Strategy:",
      Number: "01",
      content:
        "Harness the power of dynamic promotions with our 1 Day Campaign. Craft engaging offers and discounts that captivate your audience, fostering excitement and driving customer engagement. By leveraging Angel-App's intuitive tools and customizable options, businesses can create promotions that resonate with their target audience and inspire action."
    },
    {
      title: "Adaptive Promotional Algorithms:",
      Number: "02",

      content:
        "features from adaptive promotional algorithms that tailor offers based on customer behavior and preferences. The 1 Day Campaign's intelligent system ensures that promotions resonate with your target audience, maximizing the impact of your marketing efforts. By analyzing customer data in real-time, businesses can refine their promotional strategies and deliver personalized offers that drive conversion and loyalty.",
    },
    {
      title: "Urgency and Exclusivity:",
      Number: "03",

      content:
        "Instill a sense of urgency and exclusivity in your promotions. Limited-time deals encourage swift customer action, creating a buzz around your business and prompting customers to make immediate purchasing decisions. With Angel-App's 1 Day Campaign, businesses can leverage the power of urgency to drive sales and cultivate a loyal customer base.",
    },
    {
      title: "Geographic Customization:",
      Number: "04",
      content:
        "Tailor your campaign's reach with options for city-wide, province/state-wide, or country-wide exposure. Plan campaigns up to a year in advance, ensuring strategic placement and maximum impact. Once booked, locations become exclusive for the day, allowing businesses to target specific regions with precision and effectiveness.",
    },
    {
      title: "Exclusive City, Province, or Country Booking:",
      Number: "05",

      content:
        "Ensure exclusivity for your business by booking a city, province/state, or country exclusively for the day. Prevent overlapping bookings, making each campaign unique and maximizing its effectiveness. By securing exclusive access to a geographic area, businesses can stand out from competitors and capture the attention of local consumers effectively.",
    },
   
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const onTitleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const renderItems = items.map((item, index) => {
    const isActive = index === activeIndex;
    const icon = isActive ? "-" : "+";

    return (
      <div key={index} className={`accordion-item ${isActive ? "active" : ""}`}>
        <div className="accordion-title" onClick={() => onTitleClick(index)}>
          <span>
            {" "}
            <span className="acco-num">{item.Number}</span> {item.title}
          </span>

          <span className="accordion-icon">{icon}</span>
        </div>
        {isActive && <div className="accordion-content">{item.content}</div>}
      </div>
    );
  });

  return <div className="accordion">{renderItems}</div>;
};

export default AccorFeatOneDay;
