import React from "react";
import DoneIcongrey from "../../../assets/DoneIcongrey.png";
import Privacy from "../../Pricing/PricingCards/Privacy/Privacy";
import MoneyBack from "../../Pricing/moneyback/MoneyBack";
function Couponpricing() {
  const redirectToLogin = (url) => {
    window.open(url, "_blank");
  };
  const handleDownload = () => {
    const userAgent = navigator.userAgent;

    if (/android/i.test(userAgent)) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.angel.earth.angel.app",
        "_blank"
      );
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
      window.open(
        "https://apps.apple.com/us/app/angel-app/id6473820385",
        "_blank"
      );
    } else {
      window.open("https://dashboard.angel-earth.com/#/login", "_blank");
    }
  };
  const cardData = [
    {
      region: "7 Days",
      amount: "$ 75.00",
      // amount: "Free",
      features: [
        "Targeted Promotions",
        "Cost-Effective Marketing",
        "Exclusive Consumer Discounts",
        "Personalize Offers",
        "Radius and Vicinity-Based Push Notifications",
        "Includes Angel-AI",
        "Integration with other Angel-App products",
        "Push Notification included",
        "7 days = $75.00",
        "Push Radius $1.00/ Per Push",
        // "7 days = Free",
        // "Push Radius Free / Per Push",
      ],
    },

    {
      region: "14 Days",
      amount: "$ 125.00",
      // amount: "Free",
      features: [
        "Targeted Promotions",
        "Cost-Effective Marketing",
        "Exclusive Consumer Discounts",
        "Personalize Offers",
        "Radius and Vicinity-Based Push Notifications",
        "Includes Angel-AI",
        "Integration with other Angel-App products",
        "Push Notification included",
        "14 days = $125.00",
        "Push Radius $1.00/ Per Push",
        // "14 days = Free",
        // "Push Radius Free / Per Push",
      ],
    },

    {
      region: "21 Days",
      amount: "$ 175.00",
      // amount: "Free",
      features: [
        "Targeted Promotions",
        "Cost-Effective Marketing",
        "Exclusive Consumer Discounts",
        "Personalize Offers",
        "Radius and Vicinity-Based Push Notifications",
        "Includes Angel-AI",
        "Integration with other Angel-App products",
        "Push Notification included",
        "21 days = $175.00",
        "Push Radius $1.00/ Per Push",
        // "21 days = Free",
        // "Push Radius Free / Per Push",
      ],
    },

    {
      region: "30 Days",
      amount: "$ 225.00",
      // amount: "Free",
      features: [
        "Targeted Promotions",
        "Cost-Effective Marketing",
        "Exclusive Consumer Discounts",
        "Personalize Offers",
        "Radius and Vicinity-Based Push Notifications",
        "Includes Angel-AI",
        "Integration with other Angel-App products",
        "Push Notification included",
        "30 days = $225.00",
        "Push Radius $1.00/ Per Push",
        // "30 days = Free",
        // "Push Radius = Free / Per Push",
      ],
    },
  ];

  return (
    <>
      <div style={{ marginTop: "2rem" }}>
        <div style={styles.DtransferBtn}>
          <p>Coupon</p>
        </div>

        <p
          style={{
            fontSize: "2.4em",
            fontWeight: "bold",
            margin: 4,
            textAlign: "center",
          }}
          className=" Respo-PricingHeading"
        >
          Choose a plan that fits <br /> you best.
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "2.5rem",
            flexWrap: "wrap",
          }}
          className="Respo-PricingCardsOneday"
        >
          {cardData.map((card, index) => (
            <div className="card-one-day card-dtrans" key={index}>
              <div className="top-items">
                <div></div>
              </div>
              <div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    className="citywide theregion"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span>{card.region}</span>
                  </div>
                  <div className="values pricing-values">
                    <h2 className="payamount pricing-amount Headings">
                      {card.amount}
                    </h2>
                    {/* <span className="spantxt">/ per Month</span> */}
                  </div>
                </div>
                <div style={{ marginTop: "2.6rem" }}>
                  <ul className="lists pricing-list">
                    {card.features.map((feature, index) => (
                      <div key={index} className="li-data">
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={DoneIcongrey}
                            style={{
                              maxHeight: "20px",
                              maxWidth: "20px",
                              marginBottom: "3px",
                            }}
                            alt="done-icon-gray"
                          />
                          {/* <span className="featurepricing">{feature}</span> */}
                          <span className="featurepricing">
                            {feature.includes("Free") ? (
                              <b>{feature}</b>
                            ) : (
                              feature
                            )}
                          </span>
                        </div>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="btn-div pricingBTN-div">
                <button className="btn pricing-btn" onClick={handleDownload}>
                  Explore
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="why-angel-app-container">
        <h2
          style={{ textAlign: "center", marginTop: "3rem" }}
          className="Headings"
        >
          Coupon
        </h2>

        <p className="text-para-dtrans">
          Angel-App's coupon platform empowers businesses with targeted
          promotions, enhancing customer loyalty and driving sales. By
          leveraging advanced features and strategic insights, businesses can
          create personalized offers that resonate with their target audience,
          ultimately boosting brand visibility and fostering long-term customer
          relationships.
        </p>
      </div>
      <Privacy />
      <MoneyBack />
    </>
  );
}
const styles = {
  DtransferBtn: {
    width: "6.3em",
    height: "2.2em",
    borderRadius: "1.2em",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "0.9em",
    color: "#00C7FF",
    border: "#00C7FF solid 1px",
    margin: "auto",
  },

  heading: {
    textAlign: "center",
  },
};
export default Couponpricing;
