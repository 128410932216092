import React, { useEffect, useState } from "react";
import "./Company.css";
import WhyUsClick from "../whyUsClick/WhyUsClick";
import AutoSlider from "../AutoSlider/AutoSlider";
import NavigateCulture from "../../NavigateAboutComponents/Culture/NavigateCulture";
import Dataofslider from "./Dataofautoslider/Dataofslider";

function Company() {
  const [activeSection, setActiveSection] = useState("Story");
  const [showAchievement, setShowAchievement] = useState(false);
  const [showAutoSlider, setShowAutoSlider] = useState(true);
  const [showWhyUs, setShowWhyUs] = useState(false);
  const [showCulture, setShowCulture] = useState(false);

  useEffect(() => {
    showAutoSliderSection();
  }, []);

  const showAutoSliderSection = () => {
    setShowAchievement(false);
    setShowAutoSlider(true);
    setShowWhyUs(false);
    setShowCulture(false);
    setActiveSection("Story");
  };
  const showAchievementSection = () => {
    setShowAchievement(true);
    setShowAutoSlider(false);
    setShowWhyUs(false);
    setShowCulture(false);
    setActiveSection("Achievements");
  };
  const toggleCulture = () => {
    setShowCulture(true);
    setShowAchievement(false);
    setShowAutoSlider(false);
    setShowWhyUs(false);
    setActiveSection("Culture");
  };

  const toggleWhyUs = () => {
    setShowWhyUs(true);
    setShowAchievement(false);
    setShowAutoSlider(false);
    setShowCulture(false);
    setActiveSection("Why Choose Us");
  };

  return (
    <>
      <div className="CompanyHead" style={{ position: "relative" }}>
        <p
          style={{
            position: "absolute",
            left: "-2.2rem",
            top: "3rem",
            padding: "0.55rem",
            backgroundColor: "#2F2C2C",
            rotate: "-90deg",
          }}
          className="comptag"
        >
          COMPANY
        </p>

        <div className="compHead">
          <p id="comp-head-p">
            <span className="stroktxt">STORY OF</span>
            <br />
            <span className="angtxt">ANGEL-EARTH</span>
          </p>
        </div>

        <div>
          <ul>
            {/* <li
              onClick={showAchievementSection}
              style={{
                cursor: "pointer",
                borderBottom:
                  activeSection === "Achievements"
                    ? "3px solid #6E6E6E"
                    : "none",
              }}
              className="li-links"
            >
              Achievements
            </li> */}

            <li
              onClick={showAutoSliderSection}
              style={{
                cursor: "pointer",
                borderBottom:
                  activeSection === "Story" ? "3px solid #6E6E6E" : "none",
              }}
              className="li-links"
            >
              Story
            </li>
            <li
              onClick={toggleWhyUs}
              style={{
                cursor: "pointer",
                borderBottom:
                  activeSection === "Why Choose Us"
                    ? "3px solid #6E6E6E"
                    : "none",
              }}
              className="li-links"
            >
              Why Choose Us
            </li>

            <li
              onClick={toggleCulture}
              style={{
                cursor: "pointer",
                borderBottom:
                  activeSection === "Culture" ? "3px solid #6E6E6E" : "none",
              }}
              className="li-links"
            >
              Culture
            </li>
          </ul>
        </div>
      </div>

      {showAutoSlider && (
        <>
          <div className="Company-grid">
            <AutoSlider data={Dataofslider} activeSlide={2} />
          </div>

          <div>
            <div style={styles.paraAndLineDiv}>
              {/* <div
                className="verticle-line"
                style={styles.customCssverticleLine}
              ></div> */}

              <p style={styles.companyPara} className="company-parag">
                The idea for Angel-Earth’s flagship product, Angel-App, stemmed
                from the challenges our founders faced in their respective
                industries. Difficulties like managing legal documents such as
                bills of lading, which can become damaged or even wet during
                transportation; these difficulties prompted us to start looking
                into producing a robust solution. We realized through our
                experiences that traditional physical form document management
                was cumbersome, especially in handling expenses and receipts,
                leading to inefficiencies in bookkeeping and accounting. For
                consumers as well, receipts of importance can be lost or damaged
                and most people would rather take the paper receipt than the
                receipt by email because typing in your email is cumbersome and
                time-consuming. Angel-App was developed in hopes of making these
                challenges for businesses and consumers alike an issue of the
                past.
              </p>
            </div>
          </div>
        </>
      )}

      {showWhyUs && <WhyUsClick />}
      {showCulture && <NavigateCulture />}
    </>
  );
}

const styles = {
  companyHeading: {
    marginBottom: "-10px",
  },
  companyPara: {
    marginTop: "3rem",
    color: "#909090",
    textAlign: "justify",
    lineHeight: "34px",
    fontSize: "15px",
  },
  customCssverticleLine: {
    marginRight: "1rem",
  },
  paraAndLineDiv: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
    marginTop: "2.5rem",
  },
  SpacingPara: {
    paddingLeft: "4rem",
    paddingRight: "4rem",
  },
};

export default Company;
