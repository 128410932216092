import { React, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import AccorFeatPushRad from "../../services/accordion/AccorFeatPushRad";
import AccorGrowPushRad from "../../services/accordion/AccorGrowPushRad";
import Information from "../../services/information/Information";
import Servicelists from "../../services/servicelists/Servicelists";
import "./NavigateComp.css";
import PushRadius from "../../services/servicerender/ServiceComp/PushRadius";
import { useNavigate, useLocation } from "react-router-dom";

function NavigatePushRadius() {
  const navigate = useNavigate();
  const location = useLocation();
  const pushradiusref = useRef(null);
  const scrollToPushRadius = () => {
    if (location.pathname === "/services/pushradius" && pushradiusref.current) {
      window.scrollTo({
        top: pushradiusref.current.offsetTop,
        behavior: "smooth",
      });
    } else {
      navigate("/services/pushradius");
    }
  };
  useEffect(() => {
    if (location.pathname === "/services/pushradius" && pushradiusref.current) {
      window.scrollTo({
        top: pushradiusref.current.offsetTop,
        behavior: "smooth",
      });
    }
  }, [location.pathname]);
  // Define metadata content
  const pageTitle =
    "Push Radius - dynamic feature synergizing seamlessly with Coupons, Flyers, and 1 Day Campaigns to optimize promotional impact";
  const pageDescription =
    "Explore the features and growth aspects of Push Radius.  By harnessing the power of geographic precision, businesses can deliver targeted messages to nearby customers, driving engagement and boosting sales.";
  const brandLogoUrl = `${process.env.PUBLIC_URL}/A-ELogo.png`;
  const cdnImageUrl =
    "https://images.pexels.com/photos/927022/pexels-photo-927022.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:image" content={brandLogoUrl} />
        <meta
          property="og:url"
          content="https://angel-earth.com/services/pushradius"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta name="twitter:image" content={cdnImageUrl} />
      </Helmet>
      <Servicelists scrollToPushRadius={scrollToPushRadius} />
      <div ref={pushradiusref}>
        <PushRadius />
      </div>
      <div className="Services-grid">
        <div>
          <p className="Accor-head">FEATURES</p>
        </div>
        <div>
          <AccorFeatPushRad />
        </div>
      </div>

      <div className="Services-grid">
        <div>
          <p className="Accor-head">
            HERE’S HOW WE
            <br /> HELP YOU GROW
          </p>
        </div>
        <div>
          <AccorGrowPushRad />
        </div>
      </div>

      <Information />
    </>
  );
}

export default NavigatePushRadius;
